import { useContext, useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap'
import { AppContext } from '../../context/AppContext'
import fetchData from '../../utils/fetchData'
import getCookie from '../../utils/getCookie'

const AddFaq = ({
  isModal,
  setModal,
  isEdit,
  setEdit,
  setFaqData,
  faqData,
  getFaqs,
}) => {
  // ! context
  const { setAlert } = useContext(AppContext)

  // ! hooks
  const [isSubmitting, setSubmitting] = useState(false)
  const [inputData, setInputData] = useState({
    question: '',
    answer: '',
    priority: '',
    status: false,
  })
  useEffect(() => {
    faqData &&
      setInputData({
        question: faqData?.question || '',
        answer: faqData?.answer || '',
        priority: faqData?.priority || '',
        status: faqData?.status,
      })
  }, [faqData])

  // ! get input value
  const handleChange = ({ target }) => {
    const { name, value, checked } = target
    setInputData({
      ...inputData,
      [name]: name === 'status' ? checked : value,
    })
  }

  // ! send data into the server
  const handleSubmit = async (e) => {
    e.preventDefault()
    setSubmitting(true)
    try {
      if (isEdit) {
        const { data } = await fetchData.patch(
          `/faq/${faqData?._id}`,
          inputData,
          { headers: { Authorization: `Bearer ${getCookie('eyevaryToken')}` } }
        )
        setAlert({
          show: true,
          variant: 'success',
          message: 'FAQ successfully updated.',
          icon: 'mdi mdi-check',
          duration: 5,
        })
      } else {
        const { data } = await fetchData.post('/faq', inputData, {
          headers: { Authorization: `Bearer ${getCookie('eyevaryToken')}` },
        })
        setAlert({
          show: true,
          variant: 'success',
          message: 'FAQ successfully added.',
          icon: 'mdi mdi-check',
          duration: 5,
        })
      }
      getFaqs()
      hideModal()
    } catch (error) {
      console.log({ ...error }, error)
      setAlert({
        show: true,
        variant: 'danger',
        message:
          error?.response?.data?.message ||
          'Something went wrong. Please try again.',
        icon: 'mdi mdi-close',
        duration: 5,
      })
    }
    setSubmitting(false)
  }

  // ! hide modal
  const hideModal = () => {
    setModal(false)
    setEdit(false)
    setInputData({
      question: '',
      answer: '',
      priority: '',
      status: false,
    })
    setFaqData()
  }

  return (
    <Modal show={isModal} size='md' centered onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>{isEdit ? 'Update' : 'Create'} FAQ</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row>
            <Form.Group as={Col} xs={12} className='mb-3' controlId='question'>
              <Form.Label>Question</Form.Label>
              <Form.Control
                type='text'
                name='question'
                value={inputData.question}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group as={Col} xs={12} className='mb-3' controlId='answer'>
              <Form.Label>Answer</Form.Label>
              <Form.Control
                as='textarea'
                rows='4'
                type='text'
                name='answer'
                value={inputData.answer}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group as={Col} xs={12} className='mb-3' controlId='priority'>
              <Form.Label>Priority</Form.Label>
              <Form.Control
                type='number'
                name='priority'
                value={inputData.priority}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Row>
          <Form.Switch
            label={inputData.status ? 'Visible' : 'Hidden'}
            id='status'
            name='status'
            checked={inputData.status}
            onChange={handleChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button type='submit' disabled={isSubmitting}>
            {isEdit ? 'Update' : 'Submit'}
            {isSubmitting && (
              <Spinner animation='border' size='sm' className='ms-2' />
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default AddFaq
