import { useEffect, useState } from 'react'
import { Card, Dropdown, FormControl, FormSelect, Table } from 'react-bootstrap'
import Pagination from '../reusable/Pagination'
import fetchData from '../utils/fetchData'
import getCookie from '../utils/getCookie'
import Delete from './Delete'

const Review = () => {
  // ! hooks
  const [review, setReviews] = useState([])
  const [isDelete, setDelete] = useState(false)
  const [id, setId] = useState()
  // ! filter
  const [filter, setFilter] = useState({ search: '', status: '' })
  // ! pagination
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [limit, setLimit] = useState(10)

  // ! get reviews data
  const getReviews = async () => {
    try {
      const { data } = await fetchData.get(
        `/review?page=${page}&limit=${limit}&filter=${JSON.stringify(filter)}`,
        { headers: { Authorization: `Bearer ${getCookie('eyevaryToken')}` } }
      )
      setTotal(data?.total)
      setReviews(data?.review)
    } catch (error) {
      console.log({ ...error }, error)
    }
  }
  useEffect(() => {
    getReviews()
  }, [page, limit, filter])
  useEffect(() => {
    setPage(1)
  }, [filter])

  return (
    <section className='content-wrapper'>
      <Delete
        isModal={isDelete}
        setModal={setDelete}
        id={id}
        setId={setId}
        getReviews={getReviews}
      />
      <Card className='mb-4'>
        <Card.Body>
          <div className='d-flex flex-wrap align-items-center justify-content-between'>
            <Card.Title>Reviews</Card.Title>
          </div>
          <div className='filter'>
            <div>
              <FormControl
                type='text'
                placeholder='Search'
                autoComplete='off'
                name='search'
                value={filter.search}
                onChange={({ target }) =>
                  setFilter({ ...filter, [target.name]: target.value })
                }
              />
            </div>
            <div>
              <FormSelect
                autoComplete='off'
                name='status'
                value={filter.status}
                onChange={({ target }) =>
                  setFilter({ ...filter, [target.name]: target.value })
                }>
                <option value=''>All</option>
                <option value='true'>Visible</option>
                <option value='false'>Hidden</option>
              </FormSelect>
            </div>
          </div>
          <Table responsive>
            <thead>
              <tr>
                <th scope='col' style={{ minWidth: '20rem' }}>
                  Product/Service
                </th>
                <th scope='col' style={{ minWidth: '20rem' }}>
                  User
                </th>
                <th scope='col' style={{ minWidth: '35rem' }}>
                  Description
                </th>
                <th scope='col' className='text-end'>
                  Rating
                </th>
                <th scope='col'>Status</th>
                <th
                  scope='col'
                  style={{ maxWidth: '3rem', width: '3rem' }}></th>
              </tr>
            </thead>
            <tbody>
              {review?.map((review) => (
                <tr key={review?._id}>
                  <td className='align-middle'>{review?.question}</td>
                  <td className='align-middle'>{review?.question}</td>
                  <td className='align-middle'>
                    <p className='table-description'>{review?.answer}</p>
                  </td>
                  <td className='align-middle text-end'>{review?.priority}</td>
                  <td className='align-middle'>
                    {review?.status ? (
                      <span className='status status-outline-success'>
                        Visible
                      </span>
                    ) : (
                      <span className='status status-outline-danger'>
                        Hidden
                      </span>
                    )}
                  </td>
                  <td className='align-middle text-center'>
                    <Dropdown>
                      <Dropdown.Toggle as='span' className='pnt'>
                        <i className='mdi mdi-dots-vertical'></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          as='span'
                          className='pnt'
                          onClick={() => {
                            setDelete(true)
                            setId(review?._id)
                          }}>
                          Remove
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            page={page}
            setPage={setPage}
            total={total}
            limit={limit}
          />
        </Card.Body>
      </Card>
    </section>
  )
}

export default Review
