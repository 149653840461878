import { useContext, useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap'
import { AppContext } from '../context/AppContext'
import DragDrop from '../reusable/DragDrop'
import fetchData from '../utils/fetchData'
import getCookie from '../utils/getCookie'
import { staticUrl } from '../utils/url'

const AddType = ({
  isModal,
  setModal,
  isEdit,
  setEdit,
  setTypeData,
  typeData,
  getTypes,
}) => {
  // ! context
  const { setAlert } = useContext(AppContext)

  // ! hooks
  const [isSubmitting, setSubmitting] = useState(false)
  const [inputData, setInputData] = useState({
    name: '',
    slug: '',
    status: false,
  })
  const [image, setImage] = useState()

  useEffect(() => {
    typeData &&
      setInputData({
        name: typeData?.name,
        description: typeData?.description,
        status: typeData?.status,
        image: typeData?.image,
      })
    typeData?.image && setImage({ preview: staticUrl + typeData?.image || '' })
  }, [typeData])

  // ! get input value
  const handleChange = ({ target }) => {
    const { name, value, checked, type, files } = target
    if (type === 'file') {
      setImage({
        name: files?.[0]?.name,
        size: files?.[0]?.size,
        preview: URL.createObjectURL(files?.[0]),
        file: files?.[0],
      })
    } else {
      setInputData({
        ...inputData,
        [name]: name === 'status' ? checked : value,
      })
    }
  }

  // ! send data into the server
  const handleSubmit = async (e) => {
    e.preventDefault()
    setSubmitting(true)
    try {
      const formData = new FormData()
      for (let field in inputData) formData.append(field, inputData[field])
      image?.file && formData.append('image', image?.file)
      if (isEdit) {
        const { data } = await fetchData.patch(
          `/type/${typeData?._id}`,
          formData,
          { headers: { Authorization: `Bearer ${getCookie('eyevaryToken')}` } }
        )
        setAlert({
          show: true,
          variant: 'success',
          message: 'Type successfully updated.',
          icon: 'mdi mdi-check',
          duration: 5,
        })
      } else {
        const { data } = await fetchData.post('/type', formData, {
          headers: { Authorization: `Bearer ${getCookie('eyevaryToken')}` },
        })
        setAlert({
          show: true,
          variant: 'success',
          message: 'Type successfully added.',
          icon: 'mdi mdi-check',
          duration: 5,
        })
      }
      getTypes()
      hideModal()
    } catch (error) {
      console.log({ ...error }, error)
      setAlert({
        show: true,
        variant: 'danger',
        message:
          error?.response?.data?.message ||
          'Something went wrong. Please try again.',
        icon: 'mdi mdi-close',
        duration: 5,
      })
    }
    setSubmitting(false)
  }

  // ! hide modal
  const hideModal = () => {
    setModal(false)
    setEdit(false)
    setInputData({
      name: '',
      slug: '',
      status: false,
    })
    setImage()
    setTypeData()
  }

  return (
    <Modal show={isModal} size='md' centered onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>{isEdit ? 'Update' : 'Create'} Type</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row>
            <Form.Group as={Col} xs={12} className='mb-3' controlId='name'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter type name'
                name='name'
                value={inputData.name}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group
              as={Col}
              xs={12}
              className='mb-3'
              controlId='description'>
              <Form.Label>Description</Form.Label>
              <Form.Control
                as='textarea'
                rows='5'
                name='description'
                value={inputData.description}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <DragDrop
              accept='image/*'
              name='image'
              files={image}
              setFiles={setImage}
              onChange={handleChange}
            />
          </Row>
          <Form.Switch
            label={inputData.status ? 'Visible' : 'Hidden'}
            id='status'
            name='status'
            checked={inputData.status}
            onChange={handleChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button type='submit' disabled={isSubmitting}>
            {isEdit ? 'Update' : 'Submit'}
            {isSubmitting && (
              <Spinner animation='border' size='sm' className='ms-2' />
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default AddType
