import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Card, Col, Form, Row, Spinner, Table } from 'react-bootstrap'
import { AppContext } from '../context/AppContext'
import DragDrop from '../reusable/DragDrop'
import fetchData from '../utils/fetchData'
import getCookie from '../utils/getCookie'
import styles from '../../styles/scss/module/Post.module.scss'

const AddProductService = () => {
  // ! context
  const { setAlert } = useContext(AppContext)

  // ! hooks
  const [isSubmitting, setSubmitting] = useState(false)
  const [inputData, setInputData] = useState({
    title: '',
    description: '',
    publishedOn: '',
    type: '',
    category: '',
    status: true,
    review: true,
  })
  const [types, setTypes] = useState([])
  const [featuredImage, setFeaturedImage] = useState()
  const [extraImages, setExtraImages] = useState([])
  const [metaData, setMetaData] = useState({
    title: '',
    description: '',
    keywords: '',
    author: '',
    properties: [{ property: '', content: '' }],
  })
  const [subscriptions, setSubscriptions] = useState([
    { duration: '', discount: '0', price: '0' },
  ])
  const navigate = useNavigate()

  // ! get types data
  const getTypes = async () => {
    try {
      const { data } = await fetchData.get('/productService/type', {
        headers: { Authorization: `Bearer ${getCookie('eyevaryToken')}` },
      })
      setTypes(data?.types)
    } catch (error) {
      console.log({ ...error }, error)
    }
  }
  useEffect(() => {
    getTypes()
  }, [])

  // ! get input data
  const handleChange = ({ target }, from = 'normal', index) => {
    const { name, value, type, checked, files } = target
    if (from === 'normal') {
      if (type === 'file') {
        if (name === 'extraImages') {
          const images = []
          for (let i = 0; i < files.length; i++)
            images.push({
              name: files?.[i]?.name,
              size: files?.[i]?.size,
              preview: URL.createObjectURL(files?.[i]),
              file: files?.[i],
            })
          setExtraImages([...extraImages, ...images])
        } else
          setFeaturedImage({
            name: files?.[0]?.name,
            size: files?.[0]?.size,
            preview: URL.createObjectURL(files?.[0]),
            file: files?.[0],
          })
      } else {
        setInputData({
          ...inputData,
          [name]: type === 'checkbox' ? checked : value,
        })
      }
    } else if (from === 'meta') {
      if (index >= 0) {
        metaData.properties[index][name] = value
        setMetaData({ ...metaData })
      } else setMetaData({ ...metaData, [name]: value })
    } else if (from === 'subscriptions') {
      subscriptions[index][name] = value
      setSubscriptions([...subscriptions])
    }
  }

  // ! send data into the server
  const handleSubmit = async (e) => {
    e.preventDefault()
    setSubmitting(true)
    try {
      const formData = new FormData()
      inputData.metaData = JSON.stringify(metaData)
      if (inputData.category === 'Product')
        inputData.subscriptions = JSON.stringify(subscriptions)
      else delete inputData?.subscriptions
      for (let field in inputData) formData.append(field, inputData[field])
      featuredImage?.file &&
        formData.append('featuredImage', featuredImage?.file)
      for (let i = 0; i < extraImages?.length; i++)
        formData.append('extraImages', extraImages[i]?.file)

      const { data } = await fetchData.post('/productService', formData, {
        headers: { Authorization: `Bearer ${getCookie('eyevaryToken')}` },
      })
      setAlert({
        show: true,
        variant: 'success',
        message: 'Product or service successfully added',
        icon: 'mdi mdi-check',
        duration: 5,
      })
      navigate('/products&services')
    } catch (error) {
      console.log({ ...error }, error)
      setAlert({
        show: true,
        variant: 'danger',
        message:
          error?.response?.data?.message ||
          'Something went wrong. Please try again.',
        icon: 'mdi mdi-close',
        duration: 5,
      })
    }
    setSubmitting(false)
  }
  return (
    <section className='content-wrapper'>
      <Form className={styles.post_wrapper} onSubmit={handleSubmit}>
        <Row>
          <Col lg={8}>
            <div className={styles.left}>
              <div className={styles.featured_image}>
                <h6>Featured Image</h6>
                <DragDrop
                  accept='image/*'
                  name='featuredImage'
                  files={featuredImage}
                  setFiles={setFeaturedImage}
                  onChange={(e) => handleChange(e, 'normal')}
                />
                <label htmlFor='extraImages' className='d-block pnt'>
                  <i className='mdi mdi-image-size-select-actual'></i> Add Extra
                  Images
                </label>
                <input
                  accept='image/*'
                  type='file'
                  id='extraImages'
                  name='extraImages'
                  className='d-none'
                  multiple
                  onChange={(e) => handleChange(e, 'normal')}
                />
                <div className={styles.images_preview}>
                  <Row>
                    {extraImages?.map((image, i) => (
                      <Col md={4} sm={6} key={i}>
                        <div className={styles.preview_item}>
                          <span
                            onClick={() =>
                              setExtraImages(
                                extraImages?.filter((image, j) => j !== i)
                              )
                            }>
                            <i className='mdi mdi-close'></i>
                          </span>
                          <img src={image?.preview} alt={image?.name} />
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
              </div>
              <div className={styles.title}>
                <h6>Title</h6>
                <Form.Control
                  type='text'
                  size='lg'
                  placeholder='Give a title'
                  name='title'
                  value={inputData.title}
                  onChange={(e) => handleChange(e, 'normal')}
                />
              </div>
              <div className={styles.description}>
                <h6>Description</h6>
                <Form.Control
                  as='textarea'
                  rows='6'
                  size='lg'
                  placeholder='Give a description'
                  name='description'
                  value={inputData.description}
                  onChange={(e) => handleChange(e, 'normal')}
                />
              </div>
            </div>
            <Card>
              <Card.Header>Meta Information</Card.Header>
              <Card.Body>
                <Form.Group controlId='title' className='mb-3'>
                  <Form.Label>Meta Title</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter meta title'
                    name='title'
                    value={metaData.title}
                    onChange={(e) => handleChange(e, 'meta')}
                  />
                </Form.Group>
                <Form.Group controlId='description' className='mb-3'>
                  <Form.Label>Meta Description</Form.Label>
                  <Form.Control
                    as='textarea'
                    rows='5'
                    placeholder='Enter meta description'
                    name='description'
                    value={metaData.description}
                    onChange={(e) => handleChange(e, 'meta')}
                  />
                </Form.Group>
                <Row>
                  <Form.Group
                    as={Col}
                    md={6}
                    controlId='keywords'
                    className='mb-3'>
                    <Form.Label>Meta Keyword</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter meta keyword'
                      name='keywords'
                      value={metaData.keywords}
                      onChange={(e) => handleChange(e, 'meta')}
                    />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md={6}
                    controlId='author'
                    className='mb-3'>
                    <Form.Label>Meta Author</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter meta author'
                      name='author'
                      value={metaData.author}
                      onChange={(e) => handleChange(e, 'meta')}
                    />
                  </Form.Group>
                </Row>
                <p className='fw-bold mb-2'>Meta Properties</p>
                <Table responsive bordered>
                  <thead>
                    <tr>
                      <th style={{ width: '3rem' }}></th>
                      <th style={{ minWidth: '15rem' }}>Property</th>
                      <th style={{ minWidth: '25rem' }}>Content</th>
                    </tr>
                  </thead>
                  <tbody>
                    {metaData.properties.map((property, i) => (
                      <tr key={i}>
                        <td
                          className='align-middle text-center pnt'
                          onClick={() => {
                            metaData.properties.filter((property, j) => j !== i)
                            setMetaData({
                              ...metaData,
                              properties: metaData.properties.filter(
                                (property, j) => j !== i
                              ),
                            })
                          }}>
                          <span className='text-primary'>
                            <i className='mdi mdi-delete'></i>
                          </span>
                        </td>
                        <td className='align-middle'>
                          <Form.Control
                            name='property'
                            value={property.property}
                            onChange={(e) => handleChange(e, 'meta', i)}
                            required
                          />
                        </td>
                        <td className='align-middle'>
                          <Form.Control
                            name='content'
                            value={property.content}
                            onChange={(e) => handleChange(e, 'meta', i)}
                            required
                          />
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td
                        colSpan='3'
                        className='align-middle text-center pnt'
                        onClick={() => {
                          setMetaData({
                            ...metaData,
                            properties: [
                              ...metaData.properties,
                              { property: '', content: '' },
                            ],
                          })
                        }}>
                        <span className='text-success'>
                          <i className='mdi mdi-plus'></i>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4}>
            <div className={styles.right}>
              {inputData.category === 'Product' && (
                <Card className='mb-4'>
                  <Card.Header>Subscription Plans</Card.Header>
                  <Card.Body>
                    <Table responsive bordered>
                      <thead>
                        <tr>
                          <th style={{ width: '3rem' }}></th>
                          <th style={{ minWidth: '10rem' }}>Duration</th>
                          <th style={{ minWidth: '10rem' }}>Discount</th>
                          <th style={{ minWidth: '10rem' }}>Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {subscriptions.map((subscription, i) => (
                          <tr key={i}>
                            <td
                              className='align-middle text-center pnt'
                              onClick={() => {
                                setSubscriptions(
                                  subscriptions.filter((item, j) => j !== i)
                                )
                              }}>
                              <span className='text-primary'>
                                <i className='mdi mdi-delete'></i>
                              </span>
                            </td>
                            <td className='align-middle'>
                              <Form.Control
                                type='number'
                                min='0'
                                name='duration'
                                value={subscription.duration}
                                onChange={(e) =>
                                  handleChange(e, 'subscriptions', i)
                                }
                                required
                              />
                            </td>
                            <td className='align-middle'>
                              <Form.Control
                                type='number'
                                min='0'
                                max='100'
                                name='discount'
                                value={subscription.discount}
                                onChange={(e) =>
                                  handleChange(e, 'subscriptions', i)
                                }
                              />
                            </td>
                            <td className='align-middle'>
                              <Form.Control
                                type='number'
                                min='0'
                                name='price'
                                value={subscription.price}
                                onChange={(e) =>
                                  handleChange(e, 'subscriptions', i)
                                }
                                required
                              />
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td
                            className='align-middle text-center pnt'
                            colSpan='4'
                            onClick={() => {
                              setSubscriptions([
                                ...subscriptions,
                                { duration: '', discount: '0', price: '0' },
                              ])
                            }}>
                            <span className='text-success'>
                              <i className='mdi mdi-plus'></i>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              )}
              <Card>
                <Card.Header>Post Information</Card.Header>
                <Card.Body>
                  <Form.Group controlId='published_on' className='mb-3'>
                    <Form.Label>Published On</Form.Label>
                    <Form.Control
                      type='datetime-local'
                      name='publishedOn'
                      value={inputData.publishedOn}
                      onChange={(e) => handleChange(e, 'normal')}
                    />
                  </Form.Group>
                  <Form.Group controlId='type' className='mb-3'>
                    <Form.Label>Type</Form.Label>
                    <Form.Select
                      name='type'
                      value={inputData.type}
                      onChange={(e) => handleChange(e, 'normal')}
                      required>
                      <option value='' disabled>
                        Select
                      </option>
                      {types?.map((type) => (
                        <option key={type?._id} value={type?._id}>
                          {type?.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group controlId='category' className='mb-3'>
                    <Form.Label>Category</Form.Label>
                    <Form.Select
                      name='category'
                      value={inputData.category}
                      onChange={(e) => handleChange(e, 'normal')}
                      required>
                      <option value='' disabled>
                        Select
                      </option>
                      <option value='Service'>Service</option>
                      <option value='Product'>Product</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Switch
                    label='Status'
                    id='status'
                    name='status'
                    checked={inputData.status}
                    onChange={(e) => handleChange(e, 'normal')}
                  />
                  <Form.Switch
                    label='Review'
                    id='review'
                    name='review'
                    checked={inputData.review}
                    onChange={(e) => handleChange(e, 'normal')}
                  />
                </Card.Body>
                <Card.Footer>
                  <div className='text-end'>
                    <Button size='sm' type='submit' disabled={isSubmitting}>
                      Submit
                      {isSubmitting && (
                        <Spinner
                          animation='border'
                          size='sm'
                          className='ms-2'
                        />
                      )}
                    </Button>
                  </div>
                </Card.Footer>
              </Card>
            </div>
          </Col>
        </Row>
      </Form>
    </section>
  )
}

export default AddProductService
