import { useContext } from 'react'
import { Link, NavLink } from 'react-router-dom'
import styles from '../../../styles/scss/module/Sidebar.module.scss'
import { AppContext } from '../../context/AppContext'
import Item from './Item'

const Sidebar = () => {
  // ! context
  const { isSidebar, setSidebar } = useContext(AppContext)
  // ! menu options
  const menus = [
    {
      title: 'Overview',
      subMenus: [
        {
          name: 'Dashboard',
          icon: 'mdi-view-dashboard',
          to: '/',
        },
        {
          name: 'Post',
          icon: 'mdi-post-outline',
          list: [
            { to: '/posts', name: 'List' },
            { to: '/posts/add', name: 'Add' },
          ],
        },
        {
          name: 'Categories',
          icon: 'mdi-pencil-box-multiple',
          list: [
            { to: '/categories', name: 'Blog' },
            { to: '/types', name: 'Product & Service' },
          ],
        },
        {
          name: 'Client & Partner',
          icon: 'mdi-domain',
          to: '/client&partner',
        },
        {
          name: 'Testimonial',
          icon: 'mdi-message-draw',
          to: '/testimonial',
        },
        {
          name: 'Product & Service',
          icon: 'mdi-archive',
          list: [
            { to: '/products&services', name: 'List' },
            { to: '/products&services/add', name: 'Add' },
          ],
        },
        {
          name: 'Page',
          icon: 'mdi-book-open',
          list: [
            { to: '/pages/home', name: 'Home' },
            { to: '/pages/about', name: 'About' },
            { to: '/pages/team', name: 'Team' },
            { to: '/pages/contact', name: 'Contact' },
            { to: '/pages/faq', name: 'FAQ' },
          ],
        },
      ],
    },
    {
      title: 'Others',
      subMenus: [
        {
          name: 'Newsletter',
          icon: 'mdi-email',
          to: '/newsletters',
        },
        {
          name: 'User',
          icon: 'mdi-account-multiple',
          to: '/users',
        },
        {
          name: 'SEO',
          icon: 'mdi-magnify-expand',
          to: '/seo',
        },
      ],
    },
  ]
  return (
    <>
      {isSidebar && (
        <div className={styles.overlay} onClick={() => setSidebar(false)}></div>
      )}
      <aside className={styles.sidebar + (isSidebar ? ' ' + styles.show : '')}>
        <Link to='/' className={styles.logo}>
          <img src='/assets/images/logo.png' alt='SCMS' />
        </Link>
        <ul className={styles.sidebar_list}>
          {menus.map(({ title, subMenus }) => (
            <li key={title}>
              <span className={styles.title}>{title}</span>
              {subMenus.map(({ name, icon, list, to }) => (
                <ul className={styles.sub_sidebar_list} key={name}>
                  {to ? (
                    <li>
                      <NavLink
                        end
                        className={({ isActive }) =>
                          isActive ? styles.active : ''
                        }
                        to={to}>
                        <span className={styles.icon}>
                          <i className={`mdi ${icon}`}></i>
                        </span>
                        {name}
                      </NavLink>
                    </li>
                  ) : (
                    <Item name={name} icon={icon} list={list} />
                  )}
                </ul>
              ))}
            </li>
          ))}
        </ul>
      </aside>
    </>
  )
}

export default Sidebar
