const Pagination = ({ page, setPage, total, limit, className, ...props }) => {
  return (
    <>
      {Math.floor(total / limit) > 0 && (
        <div
          className={`custom-pagination${className ? ' ' + className : ''}`}
          {...props}>
          <span
            className={`prev${page === 1 ? ' disable' : ''}`}
            onClick={() => page > 1 && setPage(page - 1)}>
            <i className='mdi mdi-chevron-left'></i>
          </span>
          <span className='text'>
            {page} of {Math.ceil(total / limit)}
          </span>
          <span
            className={`next${
              Math.ceil(total / limit) === page ? ' disable' : ''
            }`}
            onClick={() =>
              page < Math.ceil(total / limit) && setPage(page + 1)
            }>
            <i className='mdi mdi-chevron-right'></i>
          </span>
        </div>
      )}
    </>
  )
}

export default Pagination
