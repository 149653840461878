import { useState } from 'react'

const DragDrop = ({
  label,
  name,
  multiple,
  accept,
  onChange,
  files,
  setFiles,
}) => {
  const [isDragOver, setDragOver] = useState(false)
  const handleDragOver = (e) => {
    e.preventDefault()
    setDragOver(true)
  }
  const handleDragLeave = (e) => {
    e.preventDefault()
    setDragOver(false)
  }
  const handleDrop = (e) => {
    e.preventDefault()
    if (multiple) {
      setFiles([...files, ...e.dataTransfer?.files])
      Array.from(e.dataTransfer.files).forEach((f) =>
        setFiles([
          ...files,
          {
            name: f?.name,
            size: f?.size,
            preview: URL.createObjectURL(f),
            file: f,
          },
        ])
      )
    } else
      setFiles({
        name: e.dataTransfer?.files?.[0]?.name,
        size: e.dataTransfer?.files?.[0]?.size,
        preview: URL.createObjectURL(e.dataTransfer?.files?.[0]),
        file: e.dataTransfer?.files?.[0],
      })
    setDragOver(false)
  }

  return (
    <div className={'drag-drop ' + (isDragOver ? 'dragged' : '')}>
      {label && (
        <label htmlFor={name} className='form-label'>
          {label}
        </label>
      )}
      {/* <label className='form-label'>Thumbnail</label> */}
      <input
        type='file'
        id={name}
        multiple={multiple}
        name={name}
        accept={accept}
        onChange={onChange}
      />
      <label
        htmlFor={name}
        className='preview'
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}>
        {!multiple && !files?.preview ? (
          <div>
            <i className='mdi mdi-tray-arrow-up'></i>
            <p>
              Drag your file here or <span>Browse Files</span>
            </p>
          </div>
        ) : (
          <img src={files?.preview} alt={files?.name} />
        )}
      </label>
      {multiple && (
        <div className='previews'>
          {files?.map((file) => (
            <div className='file'>
              <span className='remove-btn'>
                <i className='mdi mdi-close'></i>
              </span>
              <div>
                <img src={file.preview} alt={file.name} />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default DragDrop
