import { useContext, useEffect, useState } from 'react'
import { Badge, Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import styles from '../../styles/scss/module/Profile.module.scss'
import { AppContext } from '../context/AppContext'
import fetchData from '../utils/fetchData'
import getCookie from '../utils/getCookie'
import { staticUrl } from '../utils/url'

const Profile = () => {
  // ! context
  const { user, setUser, setAlert } = useContext(AppContext)
  // ! hooks
  const [isFetching, setFetching] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)
  const [isEdit, setEdit] = useState(false)
  const [isAccountEdit, setAccountEdit] = useState(false)
  const [isChange, setChange] = useState(false)
  const [userProfile, setUserProfile] = useState()
  const [inputData, setInputData] = useState({
    firstName: '',
    lastName: '',
    gender: '',
    avatar: '',
    prevRole: '',
    role: '',
    status: '',
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  })
  const { id } = useParams()

  // ! get user profile data
  const getUserData = async () => {
    try {
      const { data } = await fetchData.get(`/user/${id}`, {
        headers: { Authorization: `Bearer ${getCookie('eyevaryToken')}` },
      })
      setUserProfile(data?.user)
    } catch (error) {
      console.log({ ...error }, error)
      setAlert({
        show: true,
        variant: 'danger',
        message:
          error?.response?.data?.message ||
          'Something went wrong while fetching. Please try again.',
        icon: 'mdi mdi-close',
        duration: 5,
      })
    }
  }
  useEffect(() => {
    id && getUserData()
  }, [id])
  useEffect(() => {
    userProfile &&
      setInputData({
        firstName: userProfile?.firstName || '',
        lastName: userProfile?.lastName || '',
        email: userProfile?.email || '',
        gender: userProfile?.gender || '',
        avatar: userProfile?.avatar || '',
        prevRole: userProfile?.role || '',
        role: userProfile?.role || '',
        status: userProfile?.status || '',
      })
  }, [userProfile])

  // ! handle input change
  const handleAvatarChange = async (e) => {
    setSubmitting(true)
    const file = e.target.files[0]
    const fileExts = file?.type
    const validExts = ['image/jpeg', 'image/jpg', 'image/png']
    if (validExts.includes(fileExts)) {
      const formData = new FormData()
      userProfile?.avatar && formData.append('prevAvatar', userProfile?.avatar)
      formData.append('avatar', file)
      try {
        const { data } = await fetchData.patch(`/user/${id}`, formData, {
          headers: { Authorization: `Bearer ${getCookie('eyevaryToken')}` },
        })
        setUserProfile(data?.user)
        if (data?.user?._id === user?._id) setUser(data?.user)
        setAlert({
          show: true,
          variant: 'success',
          message: `${data?.user?.firstName} ${data?.user?.lastName} avatar updated`,
          icon: 'mdi mdi-check',
          duration: 5,
        })
      } catch (error) {
        console.log({ ...error }, error)
        setAlert({
          show: true,
          variant: 'danger',
          message:
            error?.response?.data?.message ||
            'Something went wrong while changing avatar. Please try again.',
          icon: 'mdi mdi-close',
          duration: 5,
        })
      }
    } else window.alert('Image must should be jpg, jpeg or png type')
    setSubmitting(false)
  }
  const handleChange = (e) => {
    const { name, value, checked, type } = e.target
    setInputData({
      ...inputData,
      [name]: type === 'checkbox' ? checked : value,
    })
  }

  const handleSubmit = async (e, type) => {
    e.preventDefault()
    setSubmitting(true)
    if (type === 'general') {
      if (isEdit) {
        try {
          const { data } = await fetchData.patch(
            `/user/${id}`,
            {
              firstName: inputData.firstName,
              lastName: inputData.lastName,
              gender: inputData.gender,
            },
            {
              headers: { Authorization: `Bearer ${getCookie('eyevaryToken')}` },
            }
          )
          setUserProfile(data?.user)
          if (data?.user?._id === user?._id) setUser(data?.user)
          setAlert({
            show: true,
            variant: 'success',
            message: `${data?.user?.firstName} ${data?.user?.lastName}'s general information successfully updated`,
            icon: 'mdi mdi-check',
            duration: 5,
          })
          setEdit(false)
        } catch (error) {
          console.log({ ...error }, error)
          setAlert({
            show: true,
            variant: 'danger',
            message:
              error?.response?.data?.message ||
              'Something went wrong while updating general information. Please try again.',
            icon: 'mdi mdi-close',
            duration: 5,
          })
        }
      } else setEdit(true)
    } else if (type === 'account') {
      if (isAccountEdit) {
        try {
          const { data } = await fetchData.patch(
            `/user/${id}`,
            {
              prevRole: inputData.prevRole,
              role: inputData.role,
              status: inputData.status,
            },
            {
              headers: { Authorization: `Bearer ${getCookie('eyevaryToken')}` },
            }
          )
          setUserProfile(data?.user)
          if (data?.user?._id === user?._id) setUser(data?.user)
          setAlert({
            show: true,
            variant: 'success',
            message: `${data?.user?.firstName} ${data?.user?.lastName}'s account information successfully updated`,
            icon: 'mdi mdi-check',
            duration: 5,
          })
          setAccountEdit(false)
        } catch (error) {
          console.log({ ...error }, error)
          setAlert({
            show: true,
            variant: 'danger',
            message:
              error?.response?.data?.message ||
              'Something went wrong while updating account information. Please try again.',
            icon: 'mdi mdi-close',
            duration: 5,
          })
        }
      } else setAccountEdit(true)
    } else if (type === 'password') {
      if (isChange) {
        if (inputData.newPassword !== inputData.confirmPassword) {
          setAlert({
            show: true,
            variant: 'danger',
            message: 'Password not matched',
            icon: 'mdi mdi-close',
            duration: 5,
          })
        } else {
          try {
            const { data } = await fetchData.patch(
              `/user/${id}`,
              {
                oldPassword: inputData.oldPassword,
                newPassword: inputData.newPassword,
              },
              {
                headers: {
                  Authorization: `Bearer ${getCookie('eyevaryToken')}`,
                },
              }
            )
            setUserProfile(data?.user)
            if (data?.user?._id === user?._id) setUser(data?.user)
            setAlert({
              show: true,
              variant: 'success',
              message: `${data?.user?.firstName} ${data?.user?.lastName}'s password successfully updated`,
              icon: 'mdi mdi-check',
              duration: 5,
            })
            setChange(false)
          } catch (error) {
            console.log({ ...error }, error)
            setAlert({
              show: true,
              variant: 'danger',
              message:
                error?.response?.data?.message ||
                'Something went wrong while updating password. Please try again.',
              icon: 'mdi mdi-close',
              duration: 5,
            })
          }
        }
      } else setChange(true)
    }
    setSubmitting(false)
  }
  return (
    <section className='content-wrapper'>
      <div className={styles.user}>
        <div className={styles.image}>
          <img
            src={
              userProfile?.avatar
                ? staticUrl + userProfile?.avatar
                : '/assets/images/profile.jpg'
            }
            alt={`${userProfile?.firstName} ${userProfile?.lastName}`}
          />
          <label htmlFor='avatar'>
            <i className='mdi mdi-pen'></i>
          </label>
        </div>
        <div>
          <h5>
            {userProfile?.firstName} {userProfile?.lastName}
            <input
              type='file'
              name='avatar'
              id='avatar'
              className='d-none'
              accept='image/jpg, image/png, image/jpeg'
              onChange={handleAvatarChange}
            />
          </h5>
          <span>{userProfile?.role}</span>
        </div>
      </div>
      <Form
        onSubmit={(e) => handleSubmit(e, 'general')}
        className={styles.information}>
        <div className={styles.information_title}>
          <h5>General Information</h5>
          <div className='d-flex'>
            {isEdit && (
              <Button
                variant='outline-secondary'
                size='sm'
                className='me-2'
                onClick={() => setEdit(false)}>
                Cancel
              </Button>
            )}
            <Button size='sm' type='submit'>
              {isEdit ? 'Save' : 'Edit'}
              {isSubmitting && isEdit && (
                <Spinner animation='border' size='sm' className='ms-2' />
              )}
            </Button>
          </div>
        </div>
        {isEdit ? (
          <>
            <Row>
              <Form.Group
                className='mb-3'
                controlId='firstName'
                as={Col}
                md={3}>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type='text'
                  name='firstName'
                  placeholder='Enter name'
                  value={inputData.firstName}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group className='mb-3' controlId='lastName' as={Col} md={3}>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type='text'
                  name='lastName'
                  value={inputData.lastName}
                  placeholder='Enter name'
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group className='mb-3' controlId='gender' as={Col} md={3}>
                <Form.Label>Gender</Form.Label>
                <Form.Select
                  name='gender'
                  value={inputData.gender}
                  onChange={handleChange}
                  required>
                  <option value='' disabled>
                    --Select
                  </option>
                  <option value='Male'>Male</option>
                  <option value='Female'>Female</option>
                </Form.Select>
              </Form.Group>
            </Row>
          </>
        ) : (
          <Row>
            <Col md={6} xl={4}>
              <div className={styles.information_item}>
                <p>Name</p>
                <span>
                  {userProfile?.firstName} {userProfile?.lastName}
                </span>
              </div>
              <div className={styles.information_item}>
                <p>Email</p>
                <span>{userProfile?.email || 'N/A'}</span>
              </div>
            </Col>
            <Col md={6} xl={4}>
              <div className={styles.information_item}>
                <p>Phone</p>
                <span>{userProfile?.phone || 'N/A'}</span>
              </div>
              <div className={styles.information_item}>
                <p>Gender</p>
                <span>{userProfile?.gender || 'N/A'}</span>
              </div>
            </Col>
          </Row>
        )}
      </Form>
      <Form
        className={styles.information}
        onSubmit={(e) => handleSubmit(e, 'account')}>
        <div className={styles.information_title}>
          <h5>Account Information</h5>
          {userProfile?.role !== 'Super Admin' &&
            (user?.role === 'Super Admin' ||
              user?.role === 'Admin' ||
              user?.role === 'Editor') && (
              <div className='d-flex'>
                {isAccountEdit && (
                  <Button
                    variant='outline-secondary'
                    size='sm'
                    className='me-2'
                    onClick={() => setEdit(false)}>
                    Cancel
                  </Button>
                )}
                <Button size='sm' type='submit'>
                  {isAccountEdit ? 'Save' : 'Edit'}
                  {isSubmitting && isAccountEdit && (
                    <Spinner animation='border' size='sm' className='ms-2' />
                  )}
                </Button>
              </div>
            )}
        </div>
        {isAccountEdit ? (
          <>
            <Row>
              <Form.Group className='mb-3' controlId='role' as={Col} md={2}>
                <Form.Label>Role</Form.Label>
                <Form.Select
                  name='role'
                  value={inputData.role}
                  onChange={handleChange}
                  required>
                  <option value='' disabled>
                    --Select
                  </option>
                  <option value='Admin'>Admin</option>
                  <option value='Editor'>Editor</option>
                  <option value='Member'>Member</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className='mb-3' controlId='status' as={Col} md={3}>
                <Form.Label>Status</Form.Label>
                <Form.Switch
                  name='status'
                  label={inputData.status ? 'Active' : 'Inactive'}
                  checked={inputData.status}
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>
          </>
        ) : (
          <Row>
            <Col md={6} xl={4}>
              <div className={styles.information_item}>
                <p>Status</p>
                {userProfile?.status ? (
                  <Badge bg='success'>Active</Badge>
                ) : (
                  <Badge bg='primary'>Inactive</Badge>
                )}
              </div>
              <div className={styles.information_item}>
                <p>Verified</p>
                {userProfile?.verified ? (
                  <Badge bg='success'>Verified</Badge>
                ) : (
                  <Badge bg='primary'>Unverified</Badge>
                )}
              </div>
            </Col>
            <Col md={6} xl={4}>
              <div className={styles.information_item}>
                <p>Role</p>
                <span>{userProfile?.role}</span>
              </div>
            </Col>
          </Row>
        )}
      </Form>
      <Form
        onSubmit={(e) => handleSubmit(e, 'password')}
        className={styles.information}>
        <div className={styles.information_title}>
          <h5>Account Security</h5>
          <div className='d-flex'>
            {isChange && (
              <Button
                variant='outline-secondary'
                size='sm'
                className='me-2'
                onClick={() => setChange(false)}>
                Cancel
              </Button>
            )}
            <Button size='sm' type='submit'>
              {isChange ? 'Save' : 'Change'}
              {isSubmitting && isChange && (
                <Spinner animation='border' size='sm' className='ms-2' />
              )}
            </Button>
          </div>
        </div>
        {isChange ? (
          <>
            <Row>
              <Form.Group as={Col} md={4} controlId='oldPassword'>
                <Form.Label>Old password</Form.Label>
                <Form.Control
                  type='password'
                  name='oldPassword'
                  onChange={handleChange}
                  placeholder='Enter old password'
                  required
                />
              </Form.Group>
              <Form.Group as={Col} md={4} controlId='newPassword'>
                <Form.Label>New password</Form.Label>
                <Form.Control
                  type='password'
                  name='newPassword'
                  onChange={handleChange}
                  placeholder='Enter new password'
                  required
                />
              </Form.Group>
              <Form.Group as={Col} md={4} controlId='confirmPassword'>
                <Form.Label>Confirm password</Form.Label>
                <Form.Control
                  type='password'
                  name='confirmPassword'
                  onChange={handleChange}
                  placeholder='Enter new password'
                  required
                />
              </Form.Group>
            </Row>
          </>
        ) : (
          <Row>
            <Col md={6} xl={4}>
              <div className={styles.information_item}>
                <p>Password</p>
                <span>************</span>
              </div>
            </Col>
          </Row>
        )}
      </Form>
    </section>
  )
}

export default Profile
