import { useEffect, useState } from 'react'
import {
  Button,
  Card,
  Dropdown,
  FormCheck,
  FormControl,
  FormSelect,
  Table,
} from 'react-bootstrap'
import Pagination from '../../reusable/Pagination'
import fetchData from '../../utils/fetchData'
import getCookie from '../../utils/getCookie'
import Add from './Add'
import Delete from './Delete'

const Faq = () => {
  // ! hooks
  const [isModal, setModal] = useState(false)
  const [isEdit, setEdit] = useState(false)
  const [faqs, setFaqs] = useState([])
  const [faqData, setFaqData] = useState()
  const [isDelete, setDelete] = useState(false)
  const [id, setId] = useState()
  // ! filter
  const [filter, setFilter] = useState({ search: '', status: '' })
  // ! pagination
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [limit, setLimit] = useState(10)

  // ! get faqs data
  const getFaqs = async () => {
    try {
      const { data } = await fetchData.get(
        `/faq?page=${page}&limit=${limit}&filter=${JSON.stringify(filter)}`,
        { headers: { Authorization: `Bearer ${getCookie('eyevaryToken')}` } }
      )
      setTotal(data?.total)
      setFaqs(data?.faqs)
    } catch (error) {
      console.log({ ...error }, error)
    }
  }
  useEffect(() => {
    getFaqs()
  }, [page, limit, filter])
  useEffect(() => {
    setPage(1)
  }, [filter])

  return (
    <section className='content-wrapper'>
      <Add
        isModal={isModal}
        setModal={setModal}
        isEdit={isEdit}
        setEdit={setEdit}
        setFaqData={setFaqData}
        faqData={faqData}
        getFaqs={getFaqs}
      />
      <Delete
        isModal={isDelete}
        setModal={setDelete}
        id={id}
        setId={setId}
        getFaqs={getFaqs}
      />
      <Card className='mb-4'>
        <Card.Body>
          <div className='d-flex flex-wrap align-items-center justify-content-between'>
            <Card.Title>FAQ</Card.Title>
            <div className='mb-3'>
              <Button size='sm' onClick={() => setModal(true)}>
                <i className='mdi mdi-plus me-1'></i> Add
              </Button>
            </div>
          </div>
          <div className='filter'>
            <div>
              <FormControl
                type='text'
                placeholder='Search'
                autoComplete='off'
                name='search'
                value={filter.search}
                onChange={({ target }) =>
                  setFilter({ ...filter, [target.name]: target.value })
                }
              />
            </div>
            <div>
              <FormSelect
                autoComplete='off'
                name='status'
                value={filter.status}
                onChange={({ target }) =>
                  setFilter({ ...filter, [target.name]: target.value })
                }>
                <option value=''>All</option>
                <option value='true'>Visible</option>
                <option value='false'>Hidden</option>
              </FormSelect>
            </div>
          </div>
          <Table responsive>
            <thead>
              <tr>
                <th scope='col' style={{ minWidth: '20rem' }}>
                  Question
                </th>
                <th scope='col' style={{ minWidth: '35rem' }}>
                  Answer
                </th>
                <th scope='col' className='text-end'>
                  Priority
                </th>
                <th scope='col'>Status</th>
                <th
                  scope='col'
                  style={{ maxWidth: '3rem', width: '3rem' }}></th>
              </tr>
            </thead>
            <tbody>
              {faqs?.map((faq) => (
                <tr key={faq?._id}>
                  <td className='align-middle'>{faq?.question}</td>
                  <td className='align-middle'>
                    <p className='table-description'>{faq?.answer}</p>
                  </td>
                  <td className='align-middle text-end'>{faq?.priority}</td>
                  <td className='align-middle'>
                    {faq?.status ? (
                      <span className='status status-outline-success'>
                        Visible
                      </span>
                    ) : (
                      <span className='status status-outline-danger'>
                        Hidden
                      </span>
                    )}
                  </td>
                  <td className='align-middle text-center'>
                    <Dropdown>
                      <Dropdown.Toggle as='span' className='pnt'>
                        <i className='mdi mdi-dots-vertical'></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          as='span'
                          className='pnt'
                          onClick={() => {
                            setEdit(true)
                            setModal(true)
                            setFaqData(faq)
                          }}>
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                          as='span'
                          className='pnt'
                          onClick={() => {
                            setDelete(true)
                            setId(faq?._id)
                          }}>
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            page={page}
            setPage={setPage}
            total={total}
            limit={limit}
          />
        </Card.Body>
      </Card>
    </section>
  )
}

export default Faq
