import { useContext, useEffect, useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import { AppContext } from '../context/AppContext'
import fetchData from '../utils/fetchData'
import getCookie from '../utils/getCookie'

const AddNewsletter = ({
  isModal,
  setModal,
  isEdit,
  setEdit,
  setNewsletterData,
  newsletterData,
  getNewsletters,
}) => {
  // ! context
  const { setAlert } = useContext(AppContext)

  // ! hooks
  const [isSubmitting, setSubmitting] = useState(false)
  const [inputData, setInputData] = useState({
    email: '',
    status: false,
  })
  useEffect(() => {
    newsletterData &&
      setInputData({
        email: newsletterData?.email || '',
        status: newsletterData?.status,
      })
  }, [newsletterData])

  // ! get input value
  const handleChange = ({ target }) => {
    const { name, value, checked } = target
    setInputData({
      ...inputData,
      [name]: name === 'status' ? checked : value,
    })
  }

  // ! send data into the server
  const handleSubmit = async (e) => {
    e.preventDefault()
    setSubmitting(true)
    try {
      if (isEdit) {
        const { data } = await fetchData.patch(
          `/newsletter/${newsletterData?._id}`,
          inputData,
          { headers: { Authorization: `Bearer ${getCookie('eyevaryToken')}` } }
        )
        setAlert({
          show: true,
          variant: 'success',
          message: 'Category successfully updated.',
          icon: 'mdi mdi-check',
          duration: 5,
        })
      } else {
        const { data } = await fetchData.post('/newsletter', inputData, {
          headers: { Authorization: `Bearer ${getCookie('eyevaryToken')}` },
        })
        setAlert({
          show: true,
          variant: 'success',
          message: 'Category successfully added.',
          icon: 'mdi mdi-check',
          duration: 5,
        })
      }
      getNewsletters()
      hideModal()
    } catch (error) {
      console.log({ ...error }, error)
      setAlert({
        show: true,
        variant: 'danger',
        message:
          error?.response?.data?.message ||
          'Something went wrong. Please try again.',
        icon: 'mdi mdi-close',
        duration: 5,
      })
    }
    setSubmitting(false)
  }

  // ! hide modal
  const hideModal = () => {
    setModal(false)
    setEdit(false)
    setInputData({
      email: '',
      status: false,
    })
    setNewsletterData()
  }

  return (
    <Modal show={isModal} size='md' centered onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>{isEdit ? 'Update' : 'Create'} Newsletter</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group className='mb-3' controlId='email'>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type='email'
              placeholder='Enter email address'
              name='email'
              value={inputData.email}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Switch
            label={inputData.status ? 'Active' : 'Inactive'}
            id='status'
            name='status'
            checked={inputData.status}
            onChange={handleChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button type='submit' disabled={isSubmitting}>
            {isEdit ? 'Update' : 'Submit'}
            {isSubmitting && (
              <Spinner animation='border' size='sm' className='ms-2' />
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default AddNewsletter
