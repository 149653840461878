import { useEffect, useState } from 'react'
import {
  Button,
  Card,
  Dropdown,
  FormControl,
  FormSelect,
  Table,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Pagination from '../reusable/Pagination'
import fetchData from '../utils/fetchData'
import formatDate from '../utils/formatDate'
import getCookie from '../utils/getCookie'
import { staticUrl } from '../utils/url'
import AddUser from './Add'
import Delete from './Delete'

const User = () => {
  // ! hooks
  const [isModal, setModal] = useState(false)
  const [users, setUsers] = useState([])
  const [isDelete, setDelete] = useState(false)
  const [id, setId] = useState()
  // ! filter
  const [filter, setFilter] = useState({ search: '', status: '', role: '' })
  // ! pagination
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [limit, setLimit] = useState(10)

  // ! get users data
  const getUsers = async () => {
    try {
      const { data } = await fetchData.get(
        `/user?page=${page}&limit=${limit}&filter=${JSON.stringify(filter)}`,
        { headers: { Authorization: `Bearer ${getCookie('eyevaryToken')}` } }
      )
      console.log(data)
      setTotal(data?.total)
      setUsers(data?.users)
    } catch (error) {
      console.log({ ...error }, error)
    }
  }
  useEffect(() => {
    getUsers()
  }, [page, limit, filter])
  useEffect(() => {
    setPage(1)
  }, [filter])

  return (
    <section className='content-wrapper'>
      <AddUser isModal={isModal} setModal={setModal} getUsers={getUsers} />
      <Delete
        isModal={isDelete}
        setModal={setDelete}
        id={id}
        setId={setId}
        getUsers={getUsers}
      />
      <Card className='mb-4'>
        <Card.Body>
          <div className='d-flex flex-wrap align-items-center justify-content-between'>
            <Card.Title>Users</Card.Title>
            <div className='mb-3'>
              <Button size='sm' onClick={() => setModal(true)}>
                <i className='mdi mdi-plus me-1'></i> Add
              </Button>
            </div>
          </div>
          <div className='filter'>
            <div>
              <FormControl
                type='text'
                placeholder='Search'
                autoComplete='off'
                name='search'
                value={filter.search}
                onChange={({ target }) =>
                  setFilter({ ...filter, [target.name]: target.value })
                }
              />
            </div>
            <div>
              <FormSelect
                autoComplete='off'
                name='status'
                value={filter.status}
                onChange={({ target }) =>
                  setFilter({ ...filter, [target.name]: target.value })
                }>
                <option value=''>All</option>
                <option value='true'>Active</option>
                <option value='false'>Inactive</option>
              </FormSelect>
            </div>
            <div>
              <FormSelect
                autoComplete='off'
                name='role'
                value={filter.role}
                onChange={({ target }) =>
                  setFilter({ ...filter, [target.name]: target.value })
                }>
                <option value=''>All</option>
                <option value='Super Admin'>Super Admin</option>
                <option value='Admin'>Admin</option>
                <option value='Editor'>Editor</option>
                <option value='Member'>Member</option>
              </FormSelect>
            </div>
          </div>
          <Table responsive>
            <thead>
              <tr>
                <th scope='col'>User</th>
                <th scope='col'>Mobile</th>
                <th scope='col'>Role</th>
                <th scope='col'>Status</th>
                <th scope='col'>Verified</th>
                <th scope='col'>Joined On</th>
                <th
                  scope='col'
                  style={{ maxWidth: '3rem', width: '3rem' }}></th>
              </tr>
            </thead>
            <tbody>
              {users?.map((user) => (
                <tr key={user?._id}>
                  <td className='align-middle text-nowrap'>
                    <div className='table-img'>
                      <img
                        src={
                          user?.avatar
                            ? staticUrl + user?.avatar
                            : '/assets/images/profile.jpg'
                        }
                        alt={`${user?.firstName} ${user?.lastName}`}
                      />
                      <div>
                        <h6>
                          {user?.firstName} {user?.lastName}
                        </h6>
                        <p>{user?.email || 'N/A'}</p>
                      </div>
                    </div>
                  </td>
                  <td className='align-middle text-nowrap'>
                    {user?.phone || 'N/A'}
                  </td>
                  <td className='align-middle'>
                    <span className='status status-outline-primary text-nowrap'>
                      <i className='mdi mdi-account-edit'></i> {user?.role}
                    </span>
                  </td>
                  <td className='align-middle'>
                    {user?.status ? (
                      <span className='status status-outline-success'>
                        Active
                      </span>
                    ) : (
                      <span className='status status-outline-danger'>
                        Inactive
                      </span>
                    )}
                  </td>
                  <td className='align-middle'>
                    {user?.verified ? (
                      <span className='status status-outline-success'>
                        Verified
                      </span>
                    ) : (
                      <span className='status status-outline-danger'>
                        Unverified
                      </span>
                    )}
                  </td>
                  <td className='align-middle text-nowrap'>
                    {formatDate({
                      date: user?.joinedOn || user?.createdAt,
                      time: true,
                      divider: ' - ',
                    })}
                  </td>
                  <td className='align-middle text-center'>
                    <Dropdown>
                      <Dropdown.Toggle as='span' className='pnt'>
                        <i className='mdi mdi-dots-vertical'></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item as={Link} to={`/users/${user?._id}`}>
                          View
                        </Dropdown.Item>
                        {user?.role !== 'Super Admin' && (
                          <Dropdown.Item
                            as='span'
                            className='pnt'
                            onClick={() => {
                              setDelete(true)
                              setId(user?._id)
                            }}>
                            Delete
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            page={page}
            setPage={setPage}
            total={total}
            limit={limit}
          />
        </Card.Body>
      </Card>
    </section>
  )
}

export default User
