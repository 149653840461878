import { Link } from 'react-router-dom'
import {
  Button,
  Card,
  Dropdown,
  FormControl,
  FormSelect,
  Table,
} from 'react-bootstrap'
import { useEffect, useState } from 'react'
import Pagination from '../reusable/Pagination'
import fetchData from '../utils/fetchData'
import getCookie from '../utils/getCookie'
import { staticUrl } from '../utils/url'
import formatDate from '../utils/formatDate'
import Delete from './Delete'

const Post = () => {
  const [posts, setPosts] = useState([])
  const [isDelete, setDelete] = useState(false)
  const [id, setId] = useState()
  // ! filter
  const [filter, setFilter] = useState({ search: '', status: '' })
  // ! pagination
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [limit, setLimit] = useState(10)

  // ! get posts data
  const getPosts = async () => {
    try {
      const { data } = await fetchData.get(
        `/post?page=${page}&limit=${limit}&filter=${JSON.stringify(filter)}`,
        { headers: { Authorization: `Bearer ${getCookie('eyevaryToken')}` } }
      )
      setTotal(data?.total)
      setPosts(data?.posts)
    } catch (error) {
      console.log({ ...error }, error)
    }
  }
  useEffect(() => {
    getPosts()
  }, [page, limit, filter])
  useEffect(() => {
    setPage(1)
  }, [filter])

  return (
    <section className='content-wrapper'>
      <Delete
        isModal={isDelete}
        setModal={setDelete}
        id={id}
        setId={setId}
        getPosts={getPosts}
      />
      <Card className='mb-4'>
        <Card.Body>
          <div className='d-flex flex-wrap align-items-center justify-content-between'>
            <Card.Title>Posts</Card.Title>
            <div className='mb-3'>
              <Button as={Link} size='sm' to='/posts/add'>
                <i className='mdi mdi-plus me-1'></i> Add
              </Button>
            </div>
          </div>
          <div className='filter'>
            <div>
              <FormControl
                type='text'
                placeholder='Search'
                autoComplete='off'
                name='search'
                value={filter.search}
                onChange={({ target }) =>
                  setFilter({ ...filter, [target.name]: target.value })
                }
              />
            </div>
            <div>
              <FormSelect
                name='status'
                value={filter.status}
                onChange={({ target }) =>
                  setFilter({ ...filter, [target.name]: target.value })
                }>
                <option value=''>All</option>
                <option value='true'>Published</option>
                <option value='false'>Unpublished</option>
              </FormSelect>
            </div>
          </div>
          <Table className='text-nowrap' responsive>
            <thead>
              <tr>
                <th scope='col'>Post</th>
                <th scope='col'>Author</th>
                <th scope='col'>Status</th>
                <th scope='col'>Published On</th>
                <th
                  scope='col'
                  style={{ maxWidth: '3rem', width: '3rem' }}></th>
              </tr>
            </thead>
            <tbody>
              {posts?.map((post) => (
                <tr key={post?._id}>
                  <td className='align-middle'>
                    <div className='table-img'>
                      <img
                        src={
                          post?.featuredImage
                            ? staticUrl + post?.featuredImage
                            : '/assets/images/post-1.jpg'
                        }
                        alt={post?.title}
                      />
                      <div>
                        <h6>{post?.title}</h6>
                        <p>{post?.category?.name}</p>
                      </div>
                    </div>
                  </td>
                  <td className='align-middle'>
                    <div className='table-img'>
                      <img
                        src={
                          post?.createdBy?.avatar
                            ? staticUrl + post?.createdBy?.avatar
                            : '/assets/images/profile.jpg'
                        }
                        alt={`${post?.createdBy?.firstName} ${post?.createdBy?.lastName}`}
                      />
                      <div>
                        <h6>
                          {post?.createdBy?.firstName}{' '}
                          {post?.createdBy?.lastName}
                        </h6>
                        <p>{post?.createdBy?.role}</p>
                      </div>
                    </div>
                  </td>
                  <td className='align-middle'>
                    {post?.status ? (
                      <span className='status status-outline-success'>
                        Published
                      </span>
                    ) : (
                      <span className='status status-outline-danger'>
                        Unpublished
                      </span>
                    )}
                  </td>
                  <td className='align-middle'>
                    {formatDate({
                      date: post?.publishedOn,
                      time: true,
                      divider: ' - ',
                    })}
                  </td>
                  <td className='align-middle text-center'>
                    <Dropdown>
                      <Dropdown.Toggle as='span' className='pnt'>
                        <i className='mdi mdi-dots-vertical'></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item as={Link} to={`/posts/${post?._id}`}>
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                          as='span'
                          className='pnt'
                          onClick={() => {
                            setDelete(true)
                            setId(post?._id)
                          }}>
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            page={page}
            setPage={setPage}
            total={total}
            limit={limit}
          />
        </Card.Body>
      </Card>
    </section>
  )
}

export default Post
