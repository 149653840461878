import { useContext, useState } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap'
import { AppContext } from '../context/AppContext'
import fetchData from '../utils/fetchData'
import getCookie from '../utils/getCookie'

const DeleteClientPartners = ({
  isModal,
  setModal,
  id,
  setId,
  getClientPartners,
}) => {
  // ! context
  const { setAlert } = useContext(AppContext)

  // ! hooks
  const [isSubmitting, setSubmitting] = useState(false)

  // ! delete specific clientPartner
  const handleDelete = async (e) => {
    e.preventDefault()
    setSubmitting(true)
    try {
      const { data } = await fetchData.delete(`/clientPartner/${id}`, {
        headers: { Authorization: `Bearer ${getCookie('eyevaryToken')}` },
      })
      getClientPartners()
      setSubmitting(false)
      setAlert({
        show: true,
        variant: 'success',
        message: 'Data successfully deleted.',
        icon: 'mdi mdi-check',
        duration: 5,
      })
      hideModal()
    } catch (error) {
      setSubmitting(false)
      console.log({ ...error }, error)
      setAlert({
        show: true,
        variant: 'danger',
        message:
          error?.response?.data?.message ||
          'Something went wrong. Please try again.',
        icon: 'mdi mdi-close',
        duration: 5,
      })
    }
  }

  // ! hide modal
  const hideModal = () => {
    setModal(false)
    setId()
  }

  return (
    <Modal show={isModal} size='sm' centered onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>Are You Sure</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Once you click on delete you will never recovery it again.
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleDelete} disabled={isSubmitting}>
          Delete{' '}
          {isSubmitting && (
            <Spinner animation='border' size='sm' className='ms-2' />
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteClientPartners
