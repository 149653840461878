import axios from 'axios'
import { createContext, useEffect, useState } from 'react'
import fetchData from '../utils/fetchData'
import getCookie from '../utils/getCookie'

export const AppContext = createContext()

const AppContextProvider = ({ children }) => {
  // ! hooks
  const [user, setUser] = useState()
  const [isSidebar, setSidebar] = useState(false)
  const [alert, setAlert] = useState({
    show: false,
    variant: '',
    message: '',
    icon: '',
    duration: 0,
  })
  useEffect(() => {
    alert.show &&
      setTimeout(
        () =>
          setAlert({
            show: false,
            variant: '',
            message: '',
            icon: '',
            duration: 0,
          }),
        parseInt((alert.duration || 3) * 1000)
      )
  }, [alert.show])

  // ! get user profile
  useEffect(() => {
    const token = getCookie('eyevaryToken')
    if (token && !user) {
      ;(async () => {
        try {
          const { data } = await fetchData.get('/auth/profile', {
            headers: { Authorization: `Bearer ${token}` },
          })
          setUser(data?.user)
        } catch (error) {
          console.log(error)
          console.log({ ...error })
        }
      })()
    }
  }, [user])

  return (
    <AppContext.Provider
      value={{ user, setUser, alert, setAlert, isSidebar, setSidebar }}>
      {alert.show && (
        <div className={`custom-alert ${alert.variant}`}>
          <div className='content'>
            {alert.icon && (
              <div>
                <span>
                  <i className={alert.icon}></i>
                </span>
              </div>
            )}
            {alert.message}
          </div>
        </div>
      )}
      {children}
    </AppContext.Provider>
  )
}

export default AppContextProvider
