import { useContext } from 'react'
import { Dropdown } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import styles from '../../styles/scss/module/Header.module.scss'
import { AppContext } from '../context/AppContext'
import { staticUrl } from '../utils/url'

const Header = () => {
  // ! context
  const { user, setUser, setAlert, isSidebar, setSidebar } =
    useContext(AppContext)
  const navigate = useNavigate()

  // ! logout user
  const handleLogout = () => {
    document.cookie =
      'eyevaryToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    setAlert({
      show: true,
      variant: 'success',
      message: `${user?.firstName} ${user?.lastName} you are successfully logged out.`,
      icon: 'mdi mdi-check',
      duration: 5,
    })
    setUser()
    navigate('/login')
  }

  return (
    <header className={styles.header}>
      <div className={styles.left}>
        <div className={styles.menu} onClick={() => setSidebar(!isSidebar)}>
          <span className='mdi mdi-menu'></span>
        </div>
      </div>
      <div className={styles.right}>
        <Dropdown className={styles.profile_dropdown} autoClose>
          <Dropdown.Toggle as='div' className={styles.profile_info}>
            <img
              src={
                user?.avatar
                  ? staticUrl + user?.avatar
                  : '/assets/images/profile.jpg'
              }
              alt={`${user?.firstName} ${user?.lastName}`}
            />
            <div>
              <h6>
                {user?.firstName} {user?.lastName}
              </h6>
              <small>{user?.role}</small>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item as={Link} to={`/users/${user?._id}`}>
              Profile
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item as='span' className='pnt' onClick={handleLogout}>
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </header>
  )
}

export default Header
