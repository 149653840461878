import { useContext, useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row, Spinner, Table } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { AppContext } from '../context/AppContext'
import fetchData from '../utils/fetchData'
import getCookie from '../utils/getCookie'

const Contact = () => {
  // ! context
  const { setAlert } = useContext(AppContext)

  // ! hooks
  const [isFetching, setFetching] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)
  const [inputData, setInputData] = useState({
    mapLink: '',
    phone: '',
    email: '',
    address: '',
  })
  const [metaData, setMetaData] = useState({
    title: '',
    description: '',
    keywords: '',
    author: '',
    properties: [{ property: '', content: '' }],
  })
  const navigate = useNavigate()

  // ! get page information
  const getPageInformation = async () => {
    setFetching(true)
    try {
      const { data } = await fetchData.get('/page?name=contact', {
        headers: {
          Authorization: `Bearer ${getCookie('eyevaryToken')}`,
        },
      })
      data?.page?.metaData && setMetaData(data?.page?.metaData)
      setInputData({
        mapLink: data?.page?.pageData?.mapLink || '',
        phone: data?.page?.pageData?.phone || '',
        email: data?.page?.pageData?.email || '',
        address: data?.page?.pageData?.address || '',
      })
    } catch (error) {
      console.log({ ...error }, error)
      setAlert({
        show: true,
        variant: 'danger',
        message:
          error?.response?.data?.message ||
          'Something went wrong while fetching. Please try again.',
        icon: 'mdi mdi-close',
        duration: 5,
      })
    }
    setFetching(false)
  }
  useEffect(() => {
    getPageInformation()
  }, [])

  // ! get input data
  const handleChange = ({ target }, from = 'normal', index) => {
    const { name, value, type, checked } = target
    if (from === 'normal') {
      setInputData({
        ...inputData,
        [name]: type === 'checkbox' ? checked : value,
      })
    } else if (from === 'meta') {
      if (index >= 0) {
        metaData.properties[index][name] = value
        setMetaData({ ...metaData })
      } else setMetaData({ ...metaData, [name]: value })
    }
  }

  // ! send data into the server
  const handleSubmit = async (e) => {
    e.preventDefault()
    setSubmitting(true)
    try {
      const { data } = await fetchData.patch(
        '/page?name=contact',
        { pageData: inputData, metaData },
        { headers: { Authorization: `Bearer ${getCookie('eyevaryToken')}` } }
      )
      data?.page?.metaData && setMetaData(data?.page?.metaData)
      setInputData({
        title: data?.page?.pageData?.title || '',
        subtitle: data?.page?.pageData?.subtitle || '',
        description: data?.page?.pageData?.description || '',
      })
      setAlert({
        show: true,
        variant: 'success',
        message: 'Contact page information successfully updated',
        icon: 'mdi mdi-check',
        duration: 5,
      })
      // navigate('/')
    } catch (error) {
      console.log({ ...error }, error)
      setAlert({
        show: true,
        variant: 'danger',
        message:
          error?.response?.data?.message ||
          'Something went wrong. Please try again.',
        icon: 'mdi mdi-close',
        duration: 5,
      })
    }
    setSubmitting(false)
  }

  return (
    <section className='content-wrapper'>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <Card className='mb-4'>
              <Card.Header>Update contact information</Card.Header>
              <Card.Body>
                <Form.Group controlId='mapLink' className='mb-3'>
                  <Form.Label>Map Link</Form.Label>
                  <Form.Control
                    type='text'
                    name='mapLink'
                    value={inputData.mapLink}
                    onChange={(e) => handleChange(e, 'normal')}
                    required
                  />
                </Form.Group>
                <Row>
                  <Form.Group
                    as={Col}
                    md={6}
                    controlId='phone'
                    className='mb-3'>
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type='tel'
                      name='phone'
                      value={inputData.phone}
                      onChange={(e) => handleChange(e, 'normal')}
                      required
                    />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md={6}
                    controlId='email'
                    className='mb-3'>
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type='email'
                      name='email'
                      value={inputData.email}
                      onChange={(e) => handleChange(e, 'normal')}
                      required
                    />
                  </Form.Group>
                </Row>
                <Form.Group controlId='address' className='mb-3'>
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type='text'
                    name='address'
                    value={inputData.address}
                    onChange={(e) => handleChange(e, 'normal')}
                    required
                  />
                </Form.Group>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className='mb-4'>
              <Card.Header>Meta Information</Card.Header>
              <Card.Body>
                <Form.Group controlId='title' className='mb-3'>
                  <Form.Label>Meta Title</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter meta title'
                    name='title'
                    value={metaData.title}
                    onChange={(e) => handleChange(e, 'meta')}
                  />
                </Form.Group>
                <Form.Group controlId='description' className='mb-3'>
                  <Form.Label>Meta Description</Form.Label>
                  <Form.Control
                    as='textarea'
                    rows='5'
                    placeholder='Enter meta description'
                    name='description'
                    value={metaData.description}
                    onChange={(e) => handleChange(e, 'meta')}
                  />
                </Form.Group>
                <Row>
                  <Form.Group
                    as={Col}
                    md={6}
                    controlId='keywords'
                    className='mb-3'>
                    <Form.Label>Meta Keyword</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter meta keyword'
                      name='keywords'
                      value={metaData.keywords}
                      onChange={(e) => handleChange(e, 'meta')}
                    />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md={6}
                    controlId='author'
                    className='mb-3'>
                    <Form.Label>Meta Author</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter meta author'
                      name='author'
                      value={metaData.author}
                      onChange={(e) => handleChange(e, 'meta')}
                    />
                  </Form.Group>
                </Row>
                <p className='fw-bold mb-2'>Meta Properties</p>
                <Table responsive bordered>
                  <thead>
                    <tr>
                      <th style={{ width: '3rem' }}></th>
                      <th style={{ minWidth: '15rem' }}>Property</th>
                      <th style={{ minWidth: '25rem' }}>Content</th>
                    </tr>
                  </thead>
                  <tbody>
                    {metaData.properties.map((property, i) => (
                      <tr key={i}>
                        <td
                          className='align-middle text-center pnt'
                          onClick={() => {
                            metaData.properties.filter((property, j) => j !== i)
                            setMetaData({
                              ...metaData,
                              properties: metaData.properties.filter(
                                (property, j) => j !== i
                              ),
                            })
                          }}>
                          <span className='text-primary'>
                            <i className='mdi mdi-delete'></i>
                          </span>
                        </td>
                        <td className='align-middle'>
                          <Form.Control
                            name='property'
                            value={property.property}
                            onChange={(e) => handleChange(e, 'meta', i)}
                            required
                          />
                        </td>
                        <td className='align-middle'>
                          <Form.Control
                            name='content'
                            value={property.content}
                            onChange={(e) => handleChange(e, 'meta', i)}
                            required
                          />
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td
                        colSpan='3'
                        className='align-middle text-center pnt'
                        onClick={() => {
                          setMetaData({
                            ...metaData,
                            properties: [
                              ...metaData.properties,
                              { property: '', content: '' },
                            ],
                          })
                        }}>
                        <span className='text-success'>
                          <i className='mdi mdi-plus'></i>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
              <Card.Footer>
                <div className='text-end'>
                  <Button size='sm' type='submit' disabled={isSubmitting}>
                    Submit
                    {isSubmitting && (
                      <Spinner animation='border' size='sm' className='ms-2' />
                    )}
                  </Button>
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Form>
      {/* <Card>
        <Card.Body>
          <div className='d-flex flex-wrap align-items-center justify-content-between'>
            <Card.Title>Whom wanna contact</Card.Title>
          </div>
          <div className='filter'>
            <div>
              <FormControl type='text' placeholder='Search' />
            </div>
          </div>
          <Table responsive>
            <thead>
              <tr>
                <th
                  scope='col'
                  className='text-center'
                  style={{ maxWidth: '2rem', width: '2rem' }}>
                  <FormCheck />
                </th>
                <th scope='col'>Name</th>
                <th scope='col'>Email</th>
                <th scope='col' style={{ minWidth: '35rem' }}>
                  Question
                </th>
                <th
                  scope='col'
                  style={{ maxWidth: '3rem', width: '3rem' }}></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='align-middle text-center'>
                  <FormCheck />
                </td>
                <td className='align-middle text-nowrap'>Mohammad Sayem</td>
                <td className='align-middle text-nowrap'>
                  msayem089@gmail.com
                </td>
                <td className='align-middle'>
                  <p className='table-description'>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Molestiae perspiciatis voluptatibus minima earum expedita
                    cumque, dolores fuga harum exercitationem incidunt eos quas
                    possimus esse nihil dicta. Vitae laudantium amet delectus
                    repellendus ipsum maxime, alias provident beatae, quis enim
                    rem? Quae inventore atque tempora rerum vero qui saepe ea
                    eum ipsa!
                  </p>
                </td>
                <td className='align-middle text-center'>
                  <Dropdown>
                    <Dropdown.Toggle as='span' className='pnt'>
                      <i className='mdi mdi-dots-vertical'></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item as='span' className='pnt'>
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item href='#'>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            </tbody>
          </Table>
          <Pagination
            page={page}
            setPage={setPage}
            total={total}
            limit={limit}
          />
        </Card.Body>
      </Card> */}
    </section>
  )
}

export default Contact
