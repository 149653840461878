export const staticUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:9000/assets/'
    : 'https://api.eyevary.com/assets/'

export const apiUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:9000/api/dashboard/'
    : 'https://api.eyevary.com/api/dashboard/'
export const serverUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:9000'
    : 'https://api.eyevary.com/'
