import { useEffect, useRef, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import styles from '../../../styles/scss/module/Sidebar.module.scss'
const Item = ({ name, icon, list }) => {
  // ! hooks
  const [isActive, setActive] = useState(false)
  const [height, setHeight] = useState(0)
  const listElement = useRef()
  const location = useLocation()

  useEffect(() => {
    if (list?.find((item) => item?.to === location.pathname)) {
      setActive(true)
      setHeight(listElement.current.scrollHeight)
    } else {
      setActive(false)
      setHeight(0)
    }
  }, [])

  // ! toggle list element
  const handleCollapse = () => {
    setHeight(height ? 0 : listElement.current.scrollHeight)
  }
  return (
    <>
      <li className={height ? styles.active : ''}>
        <div onClick={handleCollapse}>
          <div>
            <span className={styles.icon}>
              <i className={`mdi ${icon}`}></i>
            </span>
            {name}
          </div>
          <i
            className='mdi mdi-chevron-right'
            style={{ transform: `rotate(${height ? 90 : 0}deg)` }}></i>
        </div>
        <ul style={{ height }} ref={listElement}>
          {list.map(({ name, to }) => (
            <li key={name}>
              <NavLink
                end
                className={({ isActive }) => (isActive ? styles.active : '')}
                to={to}>
                {name}
              </NavLink>
            </li>
          ))}
        </ul>
      </li>
    </>
  )
}

export default Item
