import { useContext, useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap'
import { AppContext } from '../context/AppContext'
import fetchData from '../utils/fetchData'
import getCookie from '../utils/getCookie'

const AddCategory = ({
  isModal,
  setModal,
  isEdit,
  setEdit,
  setCategoryData,
  categoryData,
  getCategories,
}) => {
  // ! context
  const { setAlert } = useContext(AppContext)

  // ! hooks
  const [isSubmitting, setSubmitting] = useState(false)
  const [inputData, setInputData] = useState({
    name: '',
    slug: '',
    status: false,
  })
  useEffect(() => {
    categoryData &&
      setInputData({
        name: categoryData?.name,
        slug: categoryData?.slug,
        status: categoryData?.status,
      })
  }, [categoryData])

  // ! get input value
  const handleChange = ({ target }) => {
    const { name, value, checked } = target
    setInputData({
      ...inputData,
      [name]: name === 'status' ? checked : value,
    })
  }

  // ! send data into the server
  const handleSubmit = async (e) => {
    e.preventDefault()
    setSubmitting(true)
    try {
      if (isEdit) {
        const { data } = await fetchData.patch(
          `/category/${categoryData?._id}`,
          inputData,
          { headers: { Authorization: `Bearer ${getCookie('eyevaryToken')}` } }
        )
        setAlert({
          show: true,
          variant: 'success',
          message: 'Category successfully updated.',
          icon: 'mdi mdi-check',
          duration: 5,
        })
      } else {
        const { data } = await fetchData.post('/category', inputData, {
          headers: { Authorization: `Bearer ${getCookie('eyevaryToken')}` },
        })
        setAlert({
          show: true,
          variant: 'success',
          message: 'Category successfully added.',
          icon: 'mdi mdi-check',
          duration: 5,
        })
      }
      getCategories()
      hideModal()
    } catch (error) {
      console.log({ ...error }, error)
      setAlert({
        show: true,
        variant: 'danger',
        message:
          error?.response?.data?.message ||
          'Something went wrong. Please try again.',
        icon: 'mdi mdi-close',
        duration: 5,
      })
    }
    setSubmitting(false)
  }

  // ! hide modal
  const hideModal = () => {
    setModal(false)
    setEdit(false)
    setInputData({
      name: '',
      slug: '',
      status: false,
    })
    setCategoryData()
  }

  return (
    <Modal show={isModal} size='md' centered onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>{isEdit ? 'Update' : 'Create'} Category</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row>
            <Form.Group as={Col} xs={12} className='mb-3' controlId='name'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter category name'
                name='name'
                value={inputData.name}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group as={Col} xs={12} className='mb-3' controlId='slug'>
              <Form.Label>Slug</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter slug'
                name='slug'
                value={inputData.slug}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Row>
          <Form.Switch
            label={inputData.status ? 'Visible' : 'Hidden'}
            id='status'
            name='status'
            checked={inputData.status}
            onChange={handleChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button type='submit' disabled={isSubmitting}>
            {isEdit ? 'Update' : 'Submit'}
            {isSubmitting && (
              <Spinner animation='border' size='sm' className='ms-2' />
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default AddCategory
