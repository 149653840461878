import { useContext, useEffect, useState } from 'react'
import { Form, Button, Spinner } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import styles from '../../styles/scss/module/Auth.module.scss'
import { AppContext } from '../context/AppContext'
import fetchData from '../utils/fetchData'

const Login = () => {
  // ! context
  const { user, setUser, setAlert } = useContext(AppContext)
  // ! hooks
  const [isSubmitting, setSubmitting] = useState(false)
  const [isPassVisible, setPassVisible] = useState(false)
  const [inputData, setInputData] = useState({
    email: '',
    password: '',
    remember: false,
  })
  const navigate = useNavigate()

  useEffect(() => {
    if (user) navigate('/')
  }, [user])

  // ! get input values
  const handleChange = ({ target }) => {
    const { name, value, type, checked } = target
    setInputData({
      ...inputData,
      [name]: type === 'checkbox' ? checked : value,
    })
  }

  // ! submit data into the server
  const handleSubmit = async (e) => {
    e.preventDefault()
    setSubmitting(true)
    try {
      const { data } = await fetchData.post('/auth/login', inputData)
      setUser(data?.user)
      const { eyevaryToken, expiresIn } = data
      const d = new Date()
      d.setTime(d.getTime() + expiresIn * 24 * 60 * 60 * 1000)
      const expire = d.toUTCString()
      document.cookie = `eyevaryToken=${eyevaryToken};expires=${expire};path=/`
      setAlert({
        show: true,
        variant: 'success',
        message: data?.message || 'You are successfully logged in.',
        icon: 'mdi mdi-check',
        duration: 5,
      })
      navigate('/')
    } catch (error) {
      console.log({ ...error }, error)
      setAlert({
        show: true,
        variant: 'danger',
        message:
          error?.response?.data?.message ||
          'Something went wrong. Please try again.',
        icon: 'mdi mdi-close',
        duration: 5,
      })
    }
    setSubmitting(false)
  }
  return (
    <>
      <section className={styles.account}>
        <div className={styles.account_wrapper}>
          <div className={styles.logo}>
            <img src='/assets/images/logo.png' alt='' />
          </div>
          <div>
            <h3>Hi there</h3>
            <p>We're happy to have you here again!</p>
          </div>
          <Form className={styles.form} onSubmit={handleSubmit}>
            <Form.Group controlId='name' className='mb-3'>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type='email'
                placeholder='example@mail.com'
                name='email'
                value={inputData?.email}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId='password' className='mb-3'>
              <Form.Label>Password</Form.Label>
              <div className='input-with-icon'>
                <i
                  className={
                    'mdi right pnt ' +
                    (isPassVisible ? 'mdi-eye-outline' : 'mdi-eye-off-outline')
                  }
                  onClick={() => setPassVisible(!isPassVisible)}></i>
                <Form.Control
                  type={isPassVisible ? 'text' : 'password'}
                  placeholder='Minimum 6 character'
                  minLength='6'
                  name='password'
                  value={inputData?.password}
                  onChange={handleChange}
                  required
                />
              </div>
              <Form.Text as={Link} to='/forget'>
                Forget Password?
              </Form.Text>
            </Form.Group>
            <Form.Group controlId='remember' className='mb-3'>
              <Form.Check
                label='Remember me'
                name='remember'
                onChange={handleChange}
                checked={inputData.remember}
              />
            </Form.Group>
            <Button
              type='submit'
              className='icon-btn w-100 rounded mb-2'
              disabled={isSubmitting}>
              Submit
              {isSubmitting && (
                <Spinner animation='border' size='sm' className='ms-2' />
              )}
            </Button>
          </Form>
        </div>
      </section>
    </>
  )
}

export default Login
