const formatDate = ({ date, divider, time = false }) => {
  const d = new Date(date)
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const month = d.getMonth()
  const year = d.getFullYear()
  let day = d.getDate()
  day = day > 9 ? day : '0' + day
  if (time) {
    let hours = d.getHours()
    const mode = hours > 11 ? 'PM' : 'AM'
    hours = hours > 12 ? hours - 12 : hours || 12
    let minutes = d.getMinutes()
    minutes = minutes > 9 ? minutes : '0' + minutes
    const seconds = d.getSeconds()
    return `${day} ${months[month]}, ${year} ${divider} ${hours}.${minutes} ${mode}`
  } else return `${day} ${months[month]}, ${year}`
}
export default formatDate
