import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import Category from './components/category'
import Dashboard from './components/dashboard'
import Navigate from './components/navigate'
import About from './components/page/About'
import Contact from './components/page/Contact'
import Faq from './components/page/faq'
import Home from './components/page/Home'
import Team from './components/page/team'
import ClientPartner from './components/client&partner'
import Post from './components/post'
import AddPost from './components/post/Add'
import ProductService from './components/product&service'
import AddProductService from './components/product&service/Add'
import Testimonial from './components/testimonial'
import Login from './components/auth/Login'
import { useContext, useEffect } from 'react'
import getCookie from './components/utils/getCookie'
import EditPost from './components/post/Edit'
import EditProductService from './components/product&service/Edit'
import User from './components/user'
import UserProfile from './components/user/Profile'
import Seo from './components/seo'
import Newsletter from './components/newsletter'
import Review from './components/review'
import Type from './components/type'
import { AppContext } from './components/context/AppContext'

function App() {
  // ! context
  const { setSidebar } = useContext(AppContext)

  // ! hooks
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    setSidebar(false)
  }, [location.pathname])
  useEffect(() => {
    if (!getCookie('eyevaryToken')) navigate('/login')
  }, [])
  return (
    <div className='App'>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/' element={<Navigate />}>
          <Route path='' element={<Dashboard />} />
          <Route path='posts'>
            <Route path='' element={<Post />} />
            <Route path='add' element={<AddPost />} />
            <Route path=':id' element={<EditPost />} />
          </Route>
          <Route path='categories' element={<Category />} />
          <Route path='types' element={<Type />} />
          <Route path='client&partner' element={<ClientPartner />} />
          <Route path='testimonial' element={<Testimonial />} />
          <Route path='pages'>
            <Route path='home' element={<Home />} />
            <Route path='about' element={<About />} />
            <Route path='team' element={<Team />} />
            <Route path='contact' element={<Contact />} />
            <Route path='faq' element={<Faq />} />
          </Route>
          <Route path='products&services'>
            <Route path='' element={<ProductService />} />
            <Route path='add' element={<AddProductService />} />
            <Route path=':id' element={<EditProductService />} />
          </Route>
          <Route path='users'>
            <Route path='' element={<User />} />
            <Route path=':id' element={<UserProfile />} />
          </Route>
          <Route path='seo' element={<Seo />} />
          <Route path='newsletters' element={<Newsletter />} />
          <Route path='reviews' element={<Review />} />
        </Route>
      </Routes>
    </div>
  )
}

export default App
