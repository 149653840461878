import { useContext, useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row, Spinner, Table } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { AppContext } from '../context/AppContext'
import DragDrop from '../reusable/DragDrop'
import fetchData from '../utils/fetchData'
import getCookie from '../utils/getCookie'
import { staticUrl } from '../utils/url'

const Seo = () => {
  // ! context
  const { setAlert } = useContext(AppContext)

  // ! hooks
  const [isFetching, setFetching] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)
  const [inputData, setInputData] = useState({
    footerDescription: '',
  })
  const [metaData, setMetaData] = useState({
    title: '',
    description: '',
    keywords: '',
    author: '',
    properties: [{ property: '', content: '' }],
  })
  const [logo, setLogo] = useState()
  const navigate = useNavigate()

  // ! get page information
  const getPageInformation = async () => {
    setFetching(true)
    try {
      const { data } = await fetchData.get('/page?name=universal', {
        headers: {
          Authorization: `Bearer ${getCookie('eyevaryToken')}`,
        },
      })
      data?.page?.metaData && setMetaData(data?.page?.metaData)
      setInputData({
        footerDescription: data?.page?.pageData?.footerDescription || '',
        logo: data?.page?.pageData?.logo || '',
      })
      data?.page?.pageData?.logo &&
        setLogo({ preview: staticUrl + data?.page?.pageData?.logo || '' })
    } catch (error) {
      console.log({ ...error }, error)
      setAlert({
        show: true,
        variant: 'danger',
        message:
          error?.response?.data?.message ||
          'Something went wrong while fetching data. Please try again.',
        icon: 'mdi mdi-close',
        duration: 5,
      })
    }
    setFetching(false)
  }
  useEffect(() => {
    getPageInformation()
  }, [])

  // ! get input data
  const handleChange = ({ target }, from = 'normal', index) => {
    const { name, value, type, checked, files } = target
    if (from === 'normal') {
      if (type === 'file') {
        if (name === 'logo') {
          setLogo({
            name: files?.[0]?.name,
            size: files?.[0]?.size,
            preview: URL.createObjectURL(files?.[0]),
            file: files?.[0],
          })
        }
      } else
        setInputData({
          ...inputData,
          [name]: type === 'checkbox' ? checked : value,
        })
    } else if (from === 'meta') {
      if (index >= 0) {
        metaData.properties[index][name] = value
        setMetaData({ ...metaData })
      } else setMetaData({ ...metaData, [name]: value })
    }
  }

  // ! send data into the server
  const handleSubmit = async (e) => {
    e.preventDefault()
    setSubmitting(true)
    try {
      const formData = new FormData()
      formData.append('metaData', JSON.stringify(metaData))
      formData.append('pageData', JSON.stringify(inputData))
      logo?.file && formData.append('logo', logo?.file)
      const { data } = await fetchData.patch('/page?name=universal', formData, {
        headers: { Authorization: `Bearer ${getCookie('eyevaryToken')}` },
      })
      data?.page?.metaData && setMetaData(data?.page?.metaData)
      setInputData({
        footerDescription: data?.page?.pageData?.footerDescription || '',
        logo: data?.page?.pageData?.logo || '',
      })
      setAlert({
        show: true,
        variant: 'success',
        message: 'SEO information successfully updated',
        icon: 'mdi mdi-check',
        duration: 5,
      })
      // navigate('/')
    } catch (error) {
      console.log({ ...error }, error)
      setAlert({
        show: true,
        variant: 'danger',
        message:
          error?.response?.data?.message ||
          'Something went wrong. Please try again.',
        icon: 'mdi mdi-close',
        duration: 5,
      })
    }
    setSubmitting(false)
  }

  return (
    <section className='content-wrapper'>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <Card className='mb-4'>
              <Card.Header>Update Universal Information</Card.Header>
              <Card.Body>
                <Form.Group controlId='footerDescription' className='mb-3'>
                  <Form.Label>Footer Description</Form.Label>
                  <Form.Control
                    as='textarea'
                    rows='5'
                    name='footerDescription'
                    value={inputData.footerDescription}
                    onChange={(e) => handleChange(e, 'normal')}
                    required
                  />
                </Form.Group>
                <DragDrop
                  accept='image/*'
                  name='logo'
                  files={logo}
                  setFiles={setLogo}
                  label='Logo'
                  onChange={(e) => handleChange(e, 'normal')}
                />
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className='mb-4'>
              <Card.Header>Universal Meta Information</Card.Header>
              <Card.Body>
                <Form.Group controlId='title' className='mb-3'>
                  <Form.Label>Meta Title</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter meta title'
                    name='title'
                    value={metaData.title}
                    onChange={(e) => handleChange(e, 'meta')}
                    required
                  />
                </Form.Group>
                <Form.Group controlId='description' className='mb-3'>
                  <Form.Label>Meta Description</Form.Label>
                  <Form.Control
                    as='textarea'
                    rows='5'
                    placeholder='Enter meta description'
                    name='description'
                    value={metaData.description}
                    onChange={(e) => handleChange(e, 'meta')}
                    required
                  />
                </Form.Group>
                <Row>
                  <Form.Group
                    as={Col}
                    md={6}
                    controlId='keywords'
                    className='mb-3'>
                    <Form.Label>Meta Keyword</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter meta keyword'
                      name='keywords'
                      value={metaData.keywords}
                      onChange={(e) => handleChange(e, 'meta')}
                      required
                    />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md={6}
                    controlId='author'
                    className='mb-3'>
                    <Form.Label>Meta Author</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter meta author'
                      name='author'
                      value={metaData.author}
                      onChange={(e) => handleChange(e, 'meta')}
                      required
                    />
                  </Form.Group>
                </Row>
                <p className='fw-bold mb-2'>Meta Properties</p>
                <Table responsive bordered>
                  <thead>
                    <tr>
                      <th style={{ width: '3rem' }}></th>
                      <th style={{ minWidth: '15rem' }}>Property</th>
                      <th style={{ minWidth: '25rem' }}>Content</th>
                    </tr>
                  </thead>
                  <tbody>
                    {metaData.properties.map((property, i) => (
                      <tr key={i}>
                        <td
                          className='align-middle text-center pnt'
                          onClick={() => {
                            metaData.properties.filter((property, j) => j !== i)
                            setMetaData({
                              ...metaData,
                              properties: metaData.properties.filter(
                                (property, j) => j !== i
                              ),
                            })
                          }}>
                          <span className='text-primary'>
                            <i className='mdi mdi-delete'></i>
                          </span>
                        </td>
                        <td className='align-middle'>
                          <Form.Control
                            name='property'
                            value={property.property}
                            onChange={(e) => handleChange(e, 'meta', i)}
                            required
                          />
                        </td>
                        <td className='align-middle'>
                          <Form.Control
                            name='content'
                            value={property.content}
                            onChange={(e) => handleChange(e, 'meta', i)}
                            required
                          />
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td
                        colSpan='3'
                        className='align-middle text-center pnt'
                        onClick={() => {
                          setMetaData({
                            ...metaData,
                            properties: [
                              ...metaData.properties,
                              { property: '', content: '' },
                            ],
                          })
                        }}>
                        <span className='text-success'>
                          <i className='mdi mdi-plus'></i>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
              <Card.Footer>
                <div className='text-end'>
                  <Button size='sm' type='submit' disabled={isSubmitting}>
                    Submit
                    {isSubmitting && (
                      <Spinner animation='border' size='sm' className='ms-2' />
                    )}
                  </Button>
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Form>
    </section>
  )
}

export default Seo
