import { useContext, useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row, Spinner, Table } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { AppContext } from '../context/AppContext'
import fetchData from '../utils/fetchData'
import getCookie from '../utils/getCookie'

const Home = () => {
  // ! context
  const { setAlert } = useContext(AppContext)

  // ! hooks
  const [isFetching, setFetching] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)
  const [inputData, setInputData] = useState({
    heroTitle: '',
    heroDescription: '',
    isAbout: true,
    isProductService: true,
    isPartner: true,
    isTestimonial: true,
    isClient: true,
    isBlog: true,
    isContact: true,
    isNewsletter: true,
  })
  const [metaData, setMetaData] = useState({
    title: '',
    description: '',
    keywords: '',
    author: '',
    properties: [{ property: '', content: '' }],
  })
  const navigate = useNavigate()

  // ! get page information
  const getPageInformation = async () => {
    setFetching(true)
    try {
      const { data } = await fetchData.get('/page?name=home', {
        headers: {
          Authorization: `Bearer ${getCookie('eyevaryToken')}`,
        },
      })
      data?.page?.metaData && setMetaData(data?.page?.metaData)
      setInputData({
        heroTitle: data?.page?.pageData?.heroTitle || '',
        heroDescription: data?.page?.pageData?.heroDescription || '',
        isAbout: data?.page?.pageData?.isAbout || false,
        isProductService: data?.page?.pageData?.isProductService || false,
        isPartner: data?.page?.pageData?.isPartner || false,
        isTestimonial: data?.page?.pageData?.isTestimonial || false,
        isClient: data?.page?.pageData?.isClient || false,
        isBlog: data?.page?.pageData?.isBlog || false,
        isContact: data?.page?.pageData?.isContact || false,
        isNewsletter: data?.page?.pageData?.isNewsletter || false,
      })
    } catch (error) {
      console.log({ ...error }, error)
      setAlert({
        show: true,
        variant: 'danger',
        message:
          error?.response?.data?.message ||
          'Something went wrong while fetching. Please try again.',
        icon: 'mdi mdi-close',
        duration: 5,
      })
    }
    setFetching(false)
  }
  useEffect(() => {
    getPageInformation()
  }, [])

  // ! get input data
  const handleChange = ({ target }, from = 'normal', index) => {
    const { name, value, type, checked } = target
    if (from === 'normal') {
      setInputData({
        ...inputData,
        [name]: type === 'checkbox' ? checked : value,
      })
    } else if (from === 'meta') {
      if (index >= 0) {
        metaData.properties[index][name] = value
        setMetaData({ ...metaData })
      } else setMetaData({ ...metaData, [name]: value })
    }
  }

  // ! send data into the server
  const handleSubmit = async (e) => {
    e.preventDefault()
    setSubmitting(true)
    try {
      const { data } = await fetchData.patch(
        '/page?name=home',
        { pageData: inputData, metaData },
        { headers: { Authorization: `Bearer ${getCookie('eyevaryToken')}` } }
      )
      data?.page?.metaData && setMetaData(data?.page?.metaData)
      setInputData({
        heroTitle: data?.page?.pageData?.heroTitle || '',
        heroDescription: data?.page?.pageData?.heroDescription || '',
        isAbout: data?.page?.pageData?.isAbout || false,
        isProductService: data?.page?.pageData?.isProductService || false,
        isPartner: data?.page?.pageData?.isPartner || false,
        isTestimonial: data?.page?.pageData?.isTestimonial || false,
        isClient: data?.page?.pageData?.isClient || false,
        isBlog: data?.page?.pageData?.isBlog || false,
        isContact: data?.page?.pageData?.isContact || false,
        isNewsletter: data?.page?.pageData?.isNewsletter || false,
      })
      setAlert({
        show: true,
        variant: 'success',
        message: 'Home page information successfully updated',
        icon: 'mdi mdi-check',
        duration: 5,
      })
      // navigate('/')
    } catch (error) {
      console.log({ ...error }, error)
      setAlert({
        show: true,
        variant: 'danger',
        message:
          error?.response?.data?.message ||
          'Something went wrong. Please try again.',
        icon: 'mdi mdi-close',
        duration: 5,
      })
    }
    setSubmitting(false)
  }

  return (
    <section className='content-wrapper'>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <Card className='mb-4'>
              <Card.Header>Hero Section</Card.Header>
              <Card.Body>
                <Form.Group controlId='heroTitle' className='mb-3'>
                  <Form.Label>Hero Title</Form.Label>
                  <Form.Control
                    type='text'
                    name='heroTitle'
                    value={inputData.heroTitle}
                    onChange={(e) => handleChange(e, 'normal')}
                    required
                  />
                </Form.Group>
                <Form.Group controlId='heroDescription' className='mb-3'>
                  <Form.Label>Hero Description</Form.Label>
                  <Form.Control
                    as='textarea'
                    rows='5'
                    name='heroDescription'
                    value={inputData.heroDescription}
                    onChange={(e) => handleChange(e, 'normal')}
                    required
                  />
                </Form.Group>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className='mb-4'>
              <Card.Header>Other Sections Visibility</Card.Header>
              <Card.Body>
                <Form.Check
                  className='mb-2'
                  label='About'
                  id='about'
                  name='isAbout'
                  checked={inputData.isAbout}
                  onChange={(e) => handleChange(e, 'normal')}
                />
                <Form.Check
                  className='mb-2'
                  label='Our Product & Service'
                  id='isProductService'
                  name='isProductService'
                  checked={inputData.isProductService}
                  onChange={(e) => handleChange(e, 'normal')}
                />
                <Form.Check
                  className='mb-2'
                  label='Our Partners'
                  id='isPartner'
                  name='isPartner'
                  checked={inputData.isPartner}
                  onChange={(e) => handleChange(e, 'normal')}
                />
                <Form.Check
                  className='mb-2'
                  label='Testimonials'
                  id='isTestimonial'
                  name='isTestimonial'
                  checked={inputData.isTestimonial}
                  onChange={(e) => handleChange(e, 'normal')}
                />
                <Form.Check
                  className='mb-2'
                  label='Our Clients'
                  id='isClient'
                  name='isClient'
                  checked={inputData.isClient}
                  onChange={(e) => handleChange(e, 'normal')}
                />
                <Form.Check
                  className='mb-2'
                  label='Recent Blog Posts'
                  id='isBlog'
                  name='isBlog'
                  checked={inputData.isBlog}
                  onChange={(e) => handleChange(e, 'normal')}
                />
                <Form.Check
                  className='mb-2'
                  label='Contact'
                  id='isContact'
                  name='isContact'
                  checked={inputData.isContact}
                  onChange={(e) => handleChange(e, 'normal')}
                />
                <Form.Check
                  className='mb-2'
                  label='Newsletter'
                  id='isNewsletter'
                  name='isNewsletter'
                  checked={inputData.isNewsletter}
                  onChange={(e) => handleChange(e, 'normal')}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Card>
          <Card.Header>Meta Information</Card.Header>
          <Card.Body>
            <Form.Group controlId='title' className='mb-3'>
              <Form.Label>Meta Title</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter meta title'
                name='title'
                value={metaData.title}
                onChange={(e) => handleChange(e, 'meta')}
              />
            </Form.Group>
            <Form.Group controlId='description' className='mb-3'>
              <Form.Label>Meta Description</Form.Label>
              <Form.Control
                as='textarea'
                rows='5'
                placeholder='Enter meta description'
                name='description'
                value={metaData.description}
                onChange={(e) => handleChange(e, 'meta')}
              />
            </Form.Group>
            <Row>
              <Form.Group as={Col} md={6} controlId='keywords' className='mb-3'>
                <Form.Label>Meta Keyword</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter meta keyword'
                  name='keywords'
                  value={metaData.keywords}
                  onChange={(e) => handleChange(e, 'meta')}
                />
              </Form.Group>
              <Form.Group as={Col} md={6} controlId='author' className='mb-3'>
                <Form.Label>Meta Author</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter meta author'
                  name='author'
                  value={metaData.author}
                  onChange={(e) => handleChange(e, 'meta')}
                />
              </Form.Group>
            </Row>
            <p className='fw-bold mb-2'>Meta Properties</p>
            <Table responsive bordered>
              <thead>
                <tr>
                  <th style={{ width: '3rem' }}></th>
                  <th style={{ minWidth: '15rem' }}>Property</th>
                  <th style={{ minWidth: '25rem' }}>Content</th>
                </tr>
              </thead>
              <tbody>
                {metaData.properties.map((property, i) => (
                  <tr key={i}>
                    <td
                      className='align-middle text-center pnt'
                      onClick={() => {
                        metaData.properties.filter((property, j) => j !== i)
                        setMetaData({
                          ...metaData,
                          properties: metaData.properties.filter(
                            (property, j) => j !== i
                          ),
                        })
                      }}>
                      <span className='text-primary'>
                        <i className='mdi mdi-delete'></i>
                      </span>
                    </td>
                    <td className='align-middle'>
                      <Form.Control
                        name='property'
                        value={property.property}
                        onChange={(e) => handleChange(e, 'meta', i)}
                        required
                      />
                    </td>
                    <td className='align-middle'>
                      <Form.Control
                        name='content'
                        value={property.content}
                        onChange={(e) => handleChange(e, 'meta', i)}
                        required
                      />
                    </td>
                  </tr>
                ))}
                <tr>
                  <td
                    colSpan='3'
                    className='align-middle text-center pnt'
                    onClick={() => {
                      setMetaData({
                        ...metaData,
                        properties: [
                          ...metaData.properties,
                          { property: '', content: '' },
                        ],
                      })
                    }}>
                    <span className='text-success'>
                      <i className='mdi mdi-plus'></i>
                    </span>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
          <Card.Footer>
            <div className='text-end'>
              <Button type='submit' disabled={isSubmitting}>
                Submit
                {isSubmitting && (
                  <Spinner animation='border' size='sm' className='ms-2' />
                )}
              </Button>
            </div>
          </Card.Footer>
        </Card>
      </Form>
    </section>
  )
}

export default Home
