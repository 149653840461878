import { useContext, useState } from 'react'
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap'
import { AppContext } from '../context/AppContext'
import DragDrop from '../reusable/DragDrop'
import fetchData from '../utils/fetchData'
import getCookie from '../utils/getCookie'

const AddUser = ({ isModal, setModal, getUsers }) => {
  // ! context
  const { setAlert } = useContext(AppContext)

  // ! hooks
  const [isSubmitting, setSubmitting] = useState(false)
  const [inputData, setInputData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    gender: '',
    role: '',
    status: false,
    verified: false,
    password: '',
  })
  const [avatar, setAvatar] = useState()

  // ! get input value
  const handleChange = ({ target }) => {
    const { name, value, checked, type, files } = target
    if (type === 'file') {
      setAvatar({
        name: files?.[0]?.name,
        size: files?.[0]?.size,
        preview: URL.createObjectURL(files?.[0]),
        file: files?.[0],
      })
    } else {
      setInputData({
        ...inputData,
        [name]: name === 'status' || name === 'verified' ? checked : value,
      })
    }
  }

  // ! send data into the server
  const handleSubmit = async (e) => {
    e.preventDefault()
    setSubmitting(true)
    try {
      const formData = new FormData()
      for (let field in inputData) formData.append(field, inputData[field])
      avatar?.file && formData.append('avatar', avatar?.file)
      const { data } = await fetchData.post('/user', formData, {
        headers: { Authorization: `Bearer ${getCookie('eyevaryToken')}` },
      })
      getUsers()
      setAlert({
        show: true,
        variant: 'success',
        message: 'User successfully added',
        icon: 'mdi mdi-check',
        duration: 5,
      })
      hideModal()
    } catch (error) {
      console.log({ ...error }, error)
      setAlert({
        show: true,
        variant: 'danger',
        message:
          error?.response?.data?.message ||
          'Something went wrong. Please try again.',
        icon: 'mdi mdi-close',
        duration: 5,
      })
    }
    setSubmitting(false)
  }

  // ! hide modal
  const hideModal = () => {
    setModal(false)
    setAvatar()
    setInputData({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      gender: '',
      status: false,
      verified: false,
      password: '',
    })
  }

  return (
    <Modal show={isModal} size='md' centered onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>Create Team</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row>
            <Form.Group as={Col} md={6} className='mb-3' controlId='firstName'>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter first name'
                name='firstName'
                value={inputData.firstName}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group as={Col} md={6} className='mb-3' controlId='lastName'>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter last name'
                name='lastName'
                value={inputData.lastName}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group as={Col} md={6} className='mb-3' controlId='gender'>
              <Form.Label>Gender</Form.Label>
              <Form.Select
                name='gender'
                value={inputData.gender}
                onChange={handleChange}
                required>
                <option value='' disabled>
                  --Select--
                </option>
                <option value='Male'>Male</option>
                <option value='Female'>Female</option>
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} md={6} className='mb-3' controlId='role'>
              <Form.Label>Role</Form.Label>
              <Form.Select
                name='role'
                value={inputData.role}
                onChange={handleChange}
                required>
                <option value='' disabled>
                  --Select--
                </option>
                <option value='Admin'>Admin</option>
                <option value='Editor'>Editor</option>
                <option value='Member'>Member</option>
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} md={12} className='mb-3' controlId='phone'>
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type='tel'
                placeholder='Enter phone'
                name='phone'
                value={inputData.phone}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group as={Col} md={12} className='mb-3' controlId='email'>
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type='email'
                placeholder='Enter email address'
                name='email'
                value={inputData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group as={Col} md={12} className='mb-3' controlId='password'>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type='password'
                placeholder='Enter password'
                name='password'
                value={inputData.password}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <DragDrop
              accept='image/*'
              name='avatar'
              files={avatar}
              setFiles={setAvatar}
              onChange={handleChange}
            />
          </Row>
          <Form.Switch
            label={inputData.status ? 'Active' : 'Inactive'}
            id='status'
            name='status'
            checked={inputData.status}
            onChange={handleChange}
          />
          <Form.Switch
            label={inputData.verified ? 'Verified' : 'Unverified'}
            id='verified'
            name='verified'
            checked={inputData.verified}
            onChange={handleChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button type='submit' disabled={isSubmitting}>
            Submit
            {isSubmitting && (
              <Spinner animation='border' size='sm' className='ms-2' />
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default AddUser
