import { useContext, useState } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap'
import { AppContext } from '../context/AppContext'
import fetchData from '../utils/fetchData'
import getCookie from '../utils/getCookie'

const DeletePost = ({ isModal, setModal, id, setId, getPosts }) => {
  const { setAlert } = useContext(AppContext)

  // ! hooks
  const [isSubmitting, setSubmitting] = useState(false)

  // ! delete specific post
  const handleDelete = async (e) => {
    e.preventDefault()
    setSubmitting(true)
    try {
      const { data } = await fetchData.delete(`/post/${id}`, {
        headers: { Authorization: `Bearer ${getCookie('eyevaryToken')}` },
      })
      getPosts()
      setSubmitting(false)
      setAlert({
        show: true,
        variant: 'success',
        message: 'Post successfully deleted.',
        icon: 'mdi mdi-checked',
        duration: 5,
      })
      hideModal()
    } catch (error) {
      setSubmitting(false)
      console.log({ ...error }, error)
      setAlert({
        show: true,
        variant: 'danger',
        message:
          error?.response?.data?.message ||
          'Something went wrong. Please try again.',
        icon: 'mdi mdi-close',
        duration: 5,
      })
    }
  }

  // ! hide modal
  const hideModal = () => {
    setModal(false)
    setId()
  }

  return (
    <Modal show={isModal} size='sm' centered onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>Are You Sure</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Once you delete a post you will never recovery it again.
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleDelete} disabled={isSubmitting}>
          Delete{' '}
          {isSubmitting && (
            <Spinner animation='border' size='sm' className='ms-2' />
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeletePost
