import { useContext, useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap'
import { AppContext } from '../../context/AppContext'
import DragDrop from '../../reusable/DragDrop'
import fetchData from '../../utils/fetchData'
import getCookie from '../../utils/getCookie'
import { staticUrl } from '../../utils/url'

const AddTeam = ({
  isModal,
  setModal,
  isEdit,
  setEdit,
  setTeamData,
  teamData,
  getTeams,
}) => {
  // ! context
  const { setAlert } = useContext(AppContext)

  // ! hooks
  const [isSubmitting, setSubmitting] = useState(false)
  const [inputData, setInputData] = useState({
    name: '',
    position: '',
    joinedOn: '',
    description: '',
    linkedin: '',
    email: '',
    status: false,
  })
  const [avatar, setAvatar] = useState()
  useEffect(() => {
    teamData &&
      setInputData({
        name: teamData?.name || '',
        position: teamData?.position || '',
        joinedOn: teamData?.joinedOn?.split('T')?.[0] || '',
        description: teamData?.description || '',
        linkedin: teamData?.linkedin || '',
        email: teamData?.email || '',
        status: teamData?.status,
        avatar: teamData?.avatar || '',
      })
    teamData?.avatar &&
      setAvatar({ preview: staticUrl + teamData?.avatar || '' })
  }, [teamData])

  // ! get input value
  const handleChange = ({ target }) => {
    const { name, value, checked, type, files } = target
    if (type === 'file') {
      setAvatar({
        name: files?.[0]?.name,
        size: files?.[0]?.size,
        preview: URL.createObjectURL(files?.[0]),
        file: files?.[0],
      })
    } else {
      setInputData({
        ...inputData,
        [name]: name === 'status' ? checked : value,
      })
    }
  }

  // ! send data into the server
  const handleSubmit = async (e) => {
    e.preventDefault()
    setSubmitting(true)
    try {
      const formData = new FormData()
      for (let field in inputData) formData.append(field, inputData[field])
      avatar?.file && formData.append('avatar', avatar?.file)
      if (isEdit) {
        const { data } = await fetchData.patch(
          `/team/${teamData?._id}`,
          formData,
          { headers: { Authorization: `Bearer ${getCookie('eyevaryToken')}` } }
        )
        setAlert({
          show: true,
          variant: 'success',
          message: 'Team successfully updated.',
          icon: 'mdi mdi-check',
          duration: 5,
        })
      } else {
        const { data } = await fetchData.post('/team', formData, {
          headers: { Authorization: `Bearer ${getCookie('eyevaryToken')}` },
        })
        setAlert({
          show: true,
          variant: 'success',
          message: 'Team successfully added.',
          icon: 'mdi mdi-check',
          duration: 5,
        })
      }
      getTeams()
      hideModal()
    } catch (error) {
      console.log({ ...error }, error)
      setAlert({
        show: true,
        variant: 'danger',
        message:
          error?.response?.data?.message ||
          'Something went wrong. Please try again.',
        icon: 'mdi mdi-close',
        duration: 5,
      })
    }
    setSubmitting(false)
  }

  // ! hide modal
  const hideModal = () => {
    setModal(false)
    setEdit(false)
    setAvatar()
    setInputData({
      name: '',
      position: '',
      joinedOn: '',
      description: '',
      linkedin: '',
      email: '',
      status: false,
    })
    setTeamData()
  }

  return (
    <Modal show={isModal} size='md' centered onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>{isEdit ? 'Update' : 'Create'} Team</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row>
            <Form.Group as={Col} xs={12} className='mb-3' controlId='name'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter name'
                name='name'
                value={inputData.name}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group as={Col} md={6} className='mb-3' controlId='position'>
              <Form.Label>Position</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter position'
                name='position'
                value={inputData.position}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group as={Col} md={6} className='mb-3' controlId='joinedOn'>
              <Form.Label>Joined On</Form.Label>
              <Form.Control
                type='date'
                name='joinedOn'
                value={inputData.joinedOn}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group as={Col} md={6} className='mb-3' controlId='linkedin'>
              <Form.Label>Linkedin</Form.Label>
              <Form.Control
                type='text'
                name='linkedin'
                value={inputData.linkedin}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group as={Col} md={6} className='mb-3' controlId='email'>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type='email'
                name='email'
                value={inputData.email}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group
              as={Col}
              xs={12}
              className='mb-3'
              controlId='description'>
              <Form.Label>Description</Form.Label>
              <Form.Control
                as='textarea'
                rows='5'
                name='description'
                value={inputData.description}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <DragDrop
              accept='image/*'
              name='avatar'
              files={avatar}
              setFiles={setAvatar}
              onChange={handleChange}
            />
          </Row>
          <Form.Switch
            label={inputData.status ? 'Visible' : 'Hidden'}
            id='status'
            name='status'
            checked={inputData.status}
            onChange={handleChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button type='submit' disabled={isSubmitting}>
            {isEdit ? 'Update' : 'Submit'}
            {isSubmitting && (
              <Spinner animation='border' size='sm' className='ms-2' />
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default AddTeam
