import { Link } from 'react-router-dom'
import {
  Button,
  Card,
  Dropdown,
  FormControl,
  FormSelect,
  Table,
} from 'react-bootstrap'
import { useEffect, useState } from 'react'
import Pagination from '../reusable/Pagination'
import fetchData from '../utils/fetchData'
import getCookie from '../utils/getCookie'
import { staticUrl } from '../utils/url'
import formatDate from '../utils/formatDate'
import Delete from './Delete'

const ProductService = () => {
  const [productsServices, setProductsServices] = useState([])
  const [isDelete, setDelete] = useState(false)
  const [id, setId] = useState()
  const [types, setTypes] = useState([])
  // ! filter
  const [filter, setFilter] = useState({
    search: '',
    status: '',
    type: '',
    category: '',
  })
  // ! pagination
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [limit, setLimit] = useState(10)

  // ! get types data
  const getTypes = async () => {
    try {
      const { data } = await fetchData.get('/productService/type', {
        headers: { Authorization: `Bearer ${getCookie('eyevaryToken')}` },
      })
      setTypes(data?.types)
    } catch (error) {
      console.log({ ...error }, error)
    }
  }
  useEffect(() => {
    getTypes()
  }, [])

  // ! get products and services data
  const getProductsServices = async () => {
    try {
      const { data } = await fetchData.get(
        `/productService?page=${page}&limit=${limit}&filter=${JSON.stringify(
          filter
        )}`,
        { headers: { Authorization: `Bearer ${getCookie('eyevaryToken')}` } }
      )
      setTotal(data?.total)
      setProductsServices(data?.productsServices)
    } catch (error) {
      console.log({ ...error }, error)
    }
  }
  useEffect(() => {
    getProductsServices()
  }, [page, limit, filter])
  useEffect(() => {
    setPage(1)
  }, [filter])

  return (
    <section className='content-wrapper'>
      <Delete
        isModal={isDelete}
        setModal={setDelete}
        id={id}
        setId={setId}
        getProductsServices={getProductsServices}
      />
      <Card className='mb-4'>
        <Card.Body>
          <div className='d-flex flex-wrap align-items-center justify-content-between'>
            <Card.Title>Product & Service</Card.Title>
            <div className='mb-3'>
              <Button as={Link} size='sm' to='/products&services/add'>
                <i className='mdi mdi-plus me-1'></i> Add
              </Button>
            </div>
          </div>
          <div className='filter'>
            <div>
              <FormControl
                type='text'
                placeholder='Search'
                autoComplete='off'
                name='search'
                value={filter.search}
                onChange={({ target }) =>
                  setFilter({ ...filter, [target.name]: target.value })
                }
              />
            </div>
            <div>
              <FormSelect
                name='status'
                value={filter.status}
                onChange={({ target }) =>
                  setFilter({ ...filter, [target.name]: target.value })
                }>
                <option value=''>All</option>
                <option value='true'>Published</option>
                <option value='false'>Unpublished</option>
              </FormSelect>
            </div>
            <div>
              <FormSelect
                name='type'
                value={filter.type}
                onChange={({ target }) =>
                  setFilter({ ...filter, [target.name]: target.value })
                }>
                <option value=''>All</option>
                {types?.map((type) => (
                  <option key={type?._id} value={type?._id}>
                    {type?.name}
                  </option>
                ))}
              </FormSelect>
            </div>
            <div>
              <FormSelect
                name='category'
                value={filter.category}
                onChange={({ target }) =>
                  setFilter({ ...filter, [target.name]: target.value })
                }>
                <option value=''>All</option>
                <option value='Service'>Service</option>
                <option value='Product'>Product</option>
              </FormSelect>
            </div>
          </div>
          <Table className='text-nowrap' responsive>
            <thead>
              <tr>
                <th scope='col'>Product/Service</th>
                <th scope='col'>Author</th>
                {/* <th scope='col' className='text-end'>
                  Sells
                </th>
                <th scope='col' className='text-end'>
                  Reviews
                </th> */}
                <th scope='col'>Status</th>
                <th scope='col'>Published On</th>
                <th
                  scope='col'
                  style={{ maxWidth: '3rem', width: '3rem' }}></th>
              </tr>
            </thead>
            <tbody>
              {productsServices?.map((item) => (
                <tr key={item?._id}>
                  <td className='align-middle'>
                    <div className='table-img'>
                      <img
                        src={
                          item?.featuredImage
                            ? staticUrl + item?.featuredImage
                            : '/assets/images/product-1.png'
                        }
                        alt={item?.title}
                      />
                      <div>
                        <h6>{item?.title}</h6>
                        <p>{item?.type?.name}</p>
                      </div>
                    </div>
                  </td>
                  <td className='align-middle'>
                    <div className='table-img'>
                      <img
                        src={
                          item?.createdBy?.avatar
                            ? staticUrl + item?.createdBy?.avatar
                            : '/assets/images/profile.jpg'
                        }
                        alt={`${item?.createdBy?.firstName} ${item?.createdBy?.lastName}`}
                      />
                      <div>
                        <h6>
                          {item?.createdBy?.firstName}{' '}
                          {item?.createdBy?.lastName}
                        </h6>
                        <p>{item?.createdBy?.role}</p>
                      </div>
                    </div>
                  </td>
                  {/* <td className='align-middle text-end'>
                    <i className='mdi mdi-cash text-danger'></i> 20
                  </td>
                  <td className='align-middle text-end'>
                    <i className='mdi mdi-star text-warning'></i> 4.5
                  </td> */}
                  <td className='align-middle'>
                    {item?.status ? (
                      <span className='status status-outline-success'>
                        Published
                      </span>
                    ) : (
                      <span className='status status-outline-danger'>
                        Unpublished
                      </span>
                    )}
                  </td>
                  <td className='align-middle'>
                    {formatDate({
                      date: item?.publishedOn,
                      time: true,
                      divider: ' - ',
                    })}
                  </td>
                  <td className='align-middle text-center'>
                    <Dropdown>
                      <Dropdown.Toggle as='span' className='pnt'>
                        <i className='mdi mdi-dots-vertical'></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          as={Link}
                          to={`/products&services/${item?._id}`}>
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                          as='span'
                          className='pnt'
                          onClick={() => {
                            setDelete(true)
                            setId(item?._id)
                          }}>
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            page={page}
            setPage={setPage}
            total={total}
            limit={limit}
          />
        </Card.Body>
      </Card>
    </section>
  )
}

export default ProductService
