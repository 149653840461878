import { Button, Card, Col, Form, Row, Spinner, Table } from 'react-bootstrap'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import { useContext, useEffect, useState } from 'react'
import DragDrop from '../reusable/DragDrop'
import fetchData from '../utils/fetchData'
import getCookie from '../utils/getCookie'
import { staticUrl } from '../utils/url'
import { useNavigate, useParams } from 'react-router-dom'
import styles from '../../styles/scss/module/Post.module.scss'
import { AppContext } from '../context/AppContext'

const EditPost = () => {
  // ! context
  const { setAlert } = useContext(AppContext)

  // ! hooks
  const [isFetching, setFetching] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)
  const [inputData, setInputData] = useState({
    title: '',
    description: '',
    publishedOn: '',
    category: '',
    status: true,
    comment: true,
  })
  const [metaData, setMetaData] = useState({
    title: '',
    description: '',
    keywords: '',
    author: '',
    properties: [{ property: '', content: '' }],
  })
  const [featuredImage, setFeaturedImage] = useState()
  const [categories, setCategories] = useState([])
  const { id } = useParams()
  const navigate = useNavigate()

  // ! get post data
  const getPost = async () => {
    setFetching(true)
    try {
      const { data } = await fetchData.get(`/post/${id}`, {
        headers: { Authorization: `Bearer ${getCookie('eyevaryToken')}` },
      })
      const { metaData: mData, ...iData } = data?.post
      setInputData({
        title: iData?.title || '',
        description: iData?.description || '',
        publishedOn: iData?.publishedOn || '',
        category: iData?.category || '',
        status: iData?.status,
        comment: iData?.comment,
        featuredImage: iData?.featuredImage || '',
      })
      iData?.featuredImage &&
        setFeaturedImage({ preview: staticUrl + iData?.featuredImage })
      setMetaData(mData)
    } catch (error) {
      console.log({ ...error }, error)
      setAlert({
        show: true,
        variant: 'danger',
        message:
          error?.response?.data?.message ||
          'Something went wrong while fetching data. Please try again.',
        icon: 'mdi mdi-close',
        duration: 5,
      })
    }
    setFetching(false)
  }
  useEffect(() => {
    id && getPost()
  }, [id])

  // ! get categories data
  const getCategories = async () => {
    try {
      const { data } = await fetchData.get('/post/category', {
        headers: { Authorization: `Bearer ${getCookie('eyevaryToken')}` },
      })
      setCategories(data?.categories)
    } catch (error) {
      console.log({ ...error }, error)
    }
  }
  useEffect(() => {
    getCategories()
  }, [])

  // ! get input data
  const handleChange = ({ target }, from = 'normal', index) => {
    const { name, value, type, checked, files } = target
    if (from === 'normal') {
      if (type === 'file') {
        setFeaturedImage({
          name: files?.[0]?.name,
          size: files?.[0]?.size,
          preview: URL.createObjectURL(files?.[0]),
          file: files?.[0],
        })
      } else {
        setInputData({
          ...inputData,
          [name]: type === 'checkbox' ? checked : value,
        })
      }
    } else if (from === 'meta') {
      if (index >= 0) {
        metaData.properties[index][name] = value
        setMetaData({ ...metaData })
      } else setMetaData({ ...metaData, [name]: value })
    }
  }

  // ! ckeditor image upload
  const uploadAdapter = (loader) => {
    return {
      upload: () =>
        new Promise((resolve, reject) => {
          const body = new FormData()
          loader.file
            .then(async (file) => {
              body.append('ckImage', file)
              try {
                const { data } = await fetchData.post('/post/ckimage', body, {
                  headers: {
                    Authorization: `Bearer ${getCookie('eyevaryToken')}`,
                  },
                })
                resolve({ default: staticUrl + data?.imageURI })
              } catch (error) {
                reject(error)
              }
            })
            .catch((error) => reject(error))
        }),
    }
  }
  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) =>
      uploadAdapter(loader)
  }

  // ! send data into the server
  const handleSubmit = async (e) => {
    e.preventDefault()
    setSubmitting(true)
    try {
      const formData = new FormData()
      inputData.metaData = JSON.stringify(metaData)
      for (let field in inputData) formData.append(field, inputData[field])
      featuredImage.file && formData.append('featuredImage', featuredImage.file)
      const { data } = await fetchData.patch(`/post/${id}`, formData, {
        headers: { Authorization: `Bearer ${getCookie('eyevaryToken')}` },
      })
      setAlert({
        show: true,
        variant: 'success',
        message: 'Post successfully updated',
        icon: 'mdi mdi-check',
        duration: 5,
      })
      navigate('/posts')
    } catch (error) {
      console.log({ ...error }, error)
      setAlert({
        show: true,
        variant: 'danger',
        message:
          error?.response?.data?.message ||
          'Something went wrong. Please try again.',
        icon: 'mdi mdi-close',
        duration: 5,
      })
    }
    setSubmitting(false)
  }
  return (
    <section className='content-wrapper'>
      <Form className={styles.post_wrapper} onSubmit={handleSubmit}>
        <Row>
          <Col lg={8}>
            <div className={styles.left}>
              <div className={styles.featured_image}>
                <h6>Featured Image</h6>
                <DragDrop
                  accept='image/*'
                  name='featuredImage'
                  files={featuredImage}
                  setFiles={setFeaturedImage}
                  onChange={(e) => handleChange(e, 'normal')}
                />
              </div>
              <div className={styles.title}>
                <h6>Title</h6>
                <Form.Control
                  type='text'
                  size='lg'
                  placeholder='Give a title'
                  name='title'
                  value={inputData.title}
                  onChange={(e) => handleChange(e, 'normal')}
                />
              </div>
              <div className={styles.description}>
                <h6>Description</h6>
                <CKEditor
                  editor={Editor}
                  config={{
                    extraPlugins: [uploadPlugin],
                  }}
                  data={inputData.description}
                  onChange={(event, editor) => {
                    const description = editor.getData()
                    !isFetching && setInputData({ ...inputData, description })
                  }}
                />
              </div>
            </div>
            <Card>
              <Card.Header>Meta Information</Card.Header>
              <Card.Body>
                <Form.Group controlId='title' className='mb-3'>
                  <Form.Label>Meta Title</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter meta title'
                    name='title'
                    value={metaData.title}
                    onChange={(e) => handleChange(e, 'meta')}
                  />
                </Form.Group>
                <Form.Group controlId='description' className='mb-3'>
                  <Form.Label>Meta Description</Form.Label>
                  <Form.Control
                    as='textarea'
                    rows='5'
                    placeholder='Enter meta description'
                    name='description'
                    value={metaData.description}
                    onChange={(e) => handleChange(e, 'meta')}
                  />
                </Form.Group>
                <Row>
                  <Form.Group
                    as={Col}
                    md={6}
                    controlId='keywords'
                    className='mb-3'>
                    <Form.Label>Meta Keyword</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter meta keyword'
                      name='keywords'
                      value={metaData.keywords}
                      onChange={(e) => handleChange(e, 'meta')}
                    />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md={6}
                    controlId='author'
                    className='mb-3'>
                    <Form.Label>Meta Author</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter meta author'
                      name='author'
                      value={metaData.author}
                      onChange={(e) => handleChange(e, 'meta')}
                    />
                  </Form.Group>
                </Row>
                <p className='fw-bold mb-2'>Meta Properties</p>
                <Table responsive bordered>
                  <thead>
                    <tr>
                      <th style={{ width: '3rem' }}></th>
                      <th style={{ minWidth: '15rem' }}>Property</th>
                      <th style={{ minWidth: '25rem' }}>Content</th>
                    </tr>
                  </thead>
                  <tbody>
                    {metaData.properties.map((property, i) => (
                      <tr key={i}>
                        <td
                          className='align-middle text-center pnt'
                          onClick={() => {
                            metaData.properties.filter((property, j) => j !== i)
                            setMetaData({
                              ...metaData,
                              properties: metaData.properties.filter(
                                (property, j) => j !== i
                              ),
                            })
                          }}>
                          <span className='text-primary'>
                            <i className='mdi mdi-delete'></i>
                          </span>
                        </td>
                        <td className='align-middle'>
                          <Form.Control
                            name='property'
                            value={property.property}
                            onChange={(e) => handleChange(e, 'meta', i)}
                            required
                          />
                        </td>
                        <td className='align-middle'>
                          <Form.Control
                            name='content'
                            value={property.content}
                            onChange={(e) => handleChange(e, 'meta', i)}
                            required
                          />
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td
                        colSpan='3'
                        className='align-middle text-center pnt'
                        onClick={() => {
                          setMetaData({
                            ...metaData,
                            properties: [
                              ...metaData.properties,
                              { property: '', content: '' },
                            ],
                          })
                        }}>
                        <span className='text-success'>
                          <i className='mdi mdi-plus'></i>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4}>
            <div className={styles.right}>
              <Card>
                <Card.Header>Post Information</Card.Header>
                <Card.Body>
                  <Form.Group controlId='publishedOn' className='mb-3'>
                    <Form.Label>Published On</Form.Label>
                    <Form.Control
                      type='date'
                      name='publishedOn'
                      value={inputData.publishedOn}
                      onChange={(e) => handleChange(e, 'normal')}
                    />
                  </Form.Group>
                  <Form.Group controlId='category' className='mb-3'>
                    <Form.Label>Category</Form.Label>
                    <Form.Select
                      name='category'
                      value={inputData.category}
                      onChange={(e) => handleChange(e, 'normal')}>
                      <option value='' disabled>
                        Select
                      </option>
                      {categories?.map((category) => (
                        <option key={category?._id} value={category?._id}>
                          {category?.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Switch
                    label='Status'
                    id='status'
                    name='status'
                    checked={inputData.status}
                    onChange={(e) => handleChange(e, 'normal')}
                  />
                  <Form.Switch
                    label='Comment'
                    id='comment'
                    name='comment'
                    checked={inputData.comment}
                    onChange={(e) => handleChange(e, 'normal')}
                  />
                </Card.Body>
                <Card.Footer>
                  <div className='text-end'>
                    <Button size='sm' type='submit' disabled={isSubmitting}>
                      Submit
                      {isSubmitting && (
                        <Spinner
                          animation='border'
                          size='sm'
                          className='ms-2'
                        />
                      )}
                    </Button>
                  </div>
                </Card.Footer>
              </Card>
            </div>
          </Col>
        </Row>
      </Form>
    </section>
  )
}

export default EditPost
