import { Outlet } from 'react-router-dom'
import Header from '../common/Header'
import Sidebar from '../common/sidebar'

const Navigate = () => {
  return (
    <>
      <Sidebar />
      <div className='content'>
        <Header />
        <Outlet />
      </div>
    </>
  )
}

export default Navigate
