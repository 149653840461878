import { Link } from 'react-router-dom'
import { Button, Card, Col, Dropdown, Row, Table } from 'react-bootstrap'
import styles from '../../styles/scss/module/Dashboard.module.scss'
import { useEffect, useState } from 'react'
import fetchData from '../utils/fetchData'
import getCookie from '../utils/getCookie'
import formatDate from '../utils/formatDate'
import { staticUrl } from '../utils/url'

const Dashboard = () => {
  // ! hooks
  const [users, setUsers] = useState([])
  const [totalUsers, setTotalUsers] = useState(0)
  const [productsServices, setProductsServices] = useState([])
  const [totalProductsServices, setTotalProductsServices] = useState(0)
  const [posts, setPosts] = useState([])
  const [totalPosts, setTotalPosts] = useState(0)

  // ! get users data
  const getUsers = async () => {
    try {
      const { data } = await fetchData.get('/user?page=1&limit=4', {
        headers: { Authorization: `Bearer ${getCookie('eyevaryToken')}` },
      })
      console.log(data)
      setTotalUsers(data?.total)
      setUsers(data?.users)
    } catch (error) {
      console.log({ ...error }, error)
    }
  }
  useEffect(() => {
    getUsers()
  }, [])

  // ! get products and services data
  const getProductsServices = async () => {
    try {
      const { data } = await fetchData.get('/productService?page=1&limit=3', {
        headers: { Authorization: `Bearer ${getCookie('eyevaryToken')}` },
      })
      setTotalProductsServices(data?.total)
      setProductsServices(data?.productsServices)
    } catch (error) {
      console.log({ ...error }, error)
    }
  }
  useEffect(() => {
    getProductsServices()
  }, [])

  // ! get posts data
  const getPosts = async () => {
    try {
      const { data } = await fetchData.get('/post?page=1&limit=5', {
        headers: { Authorization: `Bearer ${getCookie('eyevaryToken')}` },
      })
      setTotalPosts(data?.total)
      setPosts(data?.posts)
    } catch (error) {
      console.log({ ...error }, error)
    }
  }
  useEffect(() => {
    getPosts()
  }, [])

  return (
    <section className='content-wrapper'>
      <Row>
        <Col lg={3} sm={6}>
          <Card className={styles.card}>
            <Card.Body>
              <div className='clearfix'>
                <div className='float-start'>
                  <i className='mdi mdi-archive text-info  icon-lg'></i>
                </div>
                <div className='float-end'>
                  <p className='mb-2 text-end'>Products or Services</p>
                  <div className='fluid-container'>
                    <h3 className='font-weight-medium text-end mb-0'>
                      {totalProductsServices}
                    </h3>
                  </div>
                </div>
              </div>
              <p className='text-muted mt-3 mb-0'>
                <i
                  className='mdi mdi-account-multiple me-1'
                  aria-hidden='true'></i>
                Total Products or Services
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={3} sm={6}>
          <Card className={styles.card}>
            <Card.Body>
              <div className='clearfix'>
                <div className='float-start'>
                  <i className='mdi mdi-account-box-multiple text-warning  icon-lg'></i>
                </div>
                <div className='float-end'>
                  <p className='mb-2 text-end'>Users</p>
                  <div className='fluid-container'>
                    <h3 className='font-weight-medium text-end mb-0'>
                      {totalUsers}
                    </h3>
                  </div>
                </div>
              </div>
              <p className='text-muted mt-3 mb-0'>
                <i
                  className='mdi mdi-account-multiple me-1'
                  aria-hidden='true'></i>
                Total Users
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={3} sm={6}>
          <Card className={styles.card}>
            <Card.Body>
              <div className='clearfix'>
                <div className='float-start'>
                  <i className='mdi mdi-book-open text-primary icon-lg'></i>
                </div>
                <div className='float-end'>
                  <p className='mb-2 text-end'>Posts</p>
                  <div className='fluid-container'>
                    <h3 className='font-weight-medium text-end mb-0'>
                      {totalPosts}
                    </h3>
                  </div>
                </div>
              </div>
              <p className='text-muted mt-3 mb-0'>
                <i className='mdi mdi-account me-1' aria-hidden='true'></i>
                Total Users
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Card className='mb-4'>
            <Card.Body>
              <Card.Title>Users</Card.Title>
              <Table responsive>
                <thead>
                  <tr>
                    <th scope='col'>User</th>
                    <th scope='col'>Mobile</th>
                    <th scope='col'>Role</th>
                    <th scope='col'>Status</th>
                    <th scope='col'>Verified</th>
                    <th scope='col'>Joined On</th>
                    <th
                      scope='col'
                      style={{ maxWidth: '3rem', width: '3rem' }}></th>
                  </tr>
                </thead>
                <tbody>
                  {users?.map((user) => (
                    <tr key={user?._id}>
                      <td className='align-middle text-nowrap'>
                        <div className='table-img'>
                          <img
                            src={
                              user?.avatar
                                ? staticUrl + user?.avatar
                                : '/assets/images/profile.jpg'
                            }
                            alt={`${user?.firstName} ${user?.lastName}`}
                          />
                          <div>
                            <h6>
                              {user?.firstName} {user?.lastName}
                            </h6>
                            <p>{user?.email || 'N/A'}</p>
                          </div>
                        </div>
                      </td>
                      <td className='align-middle text-nowrap'>
                        {user?.phone || 'N/A'}
                      </td>
                      <td className='align-middle'>
                        <span className='status status-outline-primary text-nowrap'>
                          <i className='mdi mdi-account-edit'></i> {user?.role}
                        </span>
                      </td>
                      <td className='align-middle'>
                        {user?.status ? (
                          <span className='status status-outline-success'>
                            Active
                          </span>
                        ) : (
                          <span className='status status-outline-danger'>
                            Inactive
                          </span>
                        )}
                      </td>
                      <td className='align-middle'>
                        {user?.verified ? (
                          <span className='status status-outline-success'>
                            Verified
                          </span>
                        ) : (
                          <span className='status status-outline-danger'>
                            Unverified
                          </span>
                        )}
                      </td>
                      <td className='align-middle text-nowrap'>
                        {formatDate({
                          date: user?.joinedOn || user?.createdAt,
                          time: true,
                          divider: ' - ',
                        })}
                      </td>
                      <td className='align-middle text-center'>
                        <Dropdown>
                          <Dropdown.Toggle as='span' className='pnt'>
                            <i className='mdi mdi-dots-vertical'></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item as={Link} to={`/users/${user?._id}`}>
                              View
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className='text-end mt-3'>
                <Button as={Link} to='/users'>
                  View All
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className='mb-4'>
            <Card.Body>
              <Card.Title>Product & Services</Card.Title>
              <Row>
                {productsServices?.map((item) => (
                  <Col key={item?._id} sm={6} md={4}>
                    <div className={styles.product_item}>
                      <div className={styles.product_image}>
                        <img
                          src={
                            item?.featuredImage
                              ? staticUrl + item?.featuredImage
                              : '/assets/images/product-3.png'
                          }
                          alt={item?.title}
                        />
                      </div>
                      <h5>{item?.title}</h5>
                      <p>{item?.description}</p>
                    </div>
                  </Col>
                ))}
              </Row>
              <div className='text-end mt-3'>
                <Button as={Link} to='/products&services'>
                  View All
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Card className='mb-4'>
        <Card.Body>
          <Card.Title>Posts</Card.Title>
          <Table className='text-nowrap' responsive>
            <thead>
              <tr>
                <th scope='col'>Post</th>
                <th scope='col'>Author</th>
                <th scope='col'>Status</th>
                <th scope='col'>Published On</th>
                <th
                  scope='col'
                  style={{ maxWidth: '3rem', width: '3rem' }}></th>
              </tr>
            </thead>
            <tbody>
              {posts?.map((post) => (
                <tr key={post?._id}>
                  <td className='align-middle'>
                    <div className='table-img'>
                      <img
                        src={
                          post?.featuredImage
                            ? staticUrl + post?.featuredImage
                            : '/assets/images/post-1.jpg'
                        }
                        alt={post?.title}
                      />
                      <div>
                        <h6>{post?.title}</h6>
                        <p>{post?.category?.name}</p>
                      </div>
                    </div>
                  </td>
                  <td className='align-middle'>
                    <div className='table-img'>
                      <img
                        src={
                          post?.createdBy?.avatar
                            ? staticUrl + post?.createdBy?.avatar
                            : '/assets/images/profile.jpg'
                        }
                        alt={`${post?.createdBy?.firstName} ${post?.createdBy?.lastName}`}
                      />
                      <div>
                        <h6>
                          {post?.createdBy?.firstName}{' '}
                          {post?.createdBy?.lastName}
                        </h6>
                        <p>{post?.createdBy?.role}</p>
                      </div>
                    </div>
                  </td>
                  <td className='align-middle'>
                    {post?.status ? (
                      <span className='status status-outline-success'>
                        Published
                      </span>
                    ) : (
                      <span className='status status-outline-danger'>
                        Unpublished
                      </span>
                    )}
                  </td>
                  <td className='align-middle'>
                    {formatDate({
                      date: post?.publishedOn,
                      time: true,
                      divider: ' - ',
                    })}
                  </td>
                  <td className='align-middle text-center'>
                    <Dropdown>
                      <Dropdown.Toggle as='span' className='pnt'>
                        <i className='mdi mdi-dots-vertical'></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item as={Link} to={`/posts/${post?._id}`}>
                          Edit
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className='text-end mt-3'>
            <Button as={Link} to='/posts'>
              View All
            </Button>
          </div>
        </Card.Body>
      </Card>
    </section>
  )
}

export default Dashboard
