import { useContext, useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap'
import { AppContext } from '../context/AppContext'
import DragDrop from '../reusable/DragDrop'
import fetchData from '../utils/fetchData'
import getCookie from '../utils/getCookie'
import { staticUrl } from '../utils/url'

const AddClientPartner = ({
  isModal,
  setModal,
  isEdit,
  setEdit,
  setClientPartnerData,
  clientPartnerData,
  getClientPartners,
}) => {
  // ! context
  const { setAlert } = useContext(AppContext)

  // ! hooks
  const [isSubmitting, setSubmitting] = useState(false)
  const [inputData, setInputData] = useState({
    name: '',
    type: '',
    joinedOn: '',
    status: false,
  })
  const [logo, setLogo] = useState()
  useEffect(() => {
    clientPartnerData &&
      setInputData({
        name: clientPartnerData?.name || '',
        type: clientPartnerData?.type || '',
        joinedOn: clientPartnerData?.joinedOn?.split('T')?.[0] || '',
        status: clientPartnerData?.status || '',
        logo: clientPartnerData?.logo || '',
      })
    clientPartnerData?.logo &&
      setLogo({ preview: staticUrl + clientPartnerData?.logo || '' })
  }, [clientPartnerData])

  // ! get input value
  const handleChange = ({ target }) => {
    const { name, value, checked, type, files } = target
    if (type === 'file') {
      setLogo({
        name: files?.[0]?.name,
        size: files?.[0]?.size,
        preview: URL.createObjectURL(files?.[0]),
        file: files?.[0],
      })
    } else {
      setInputData({
        ...inputData,
        [name]: name === 'status' ? checked : value,
      })
    }
  }

  // ! send data into the server
  const handleSubmit = async (e) => {
    e.preventDefault()
    setSubmitting(true)
    try {
      const formData = new FormData()
      for (let field in inputData) formData.append(field, inputData[field])
      logo?.file && formData.append('logo', logo?.file)
      if (isEdit) {
        const { data } = await fetchData.patch(
          `/clientPartner/${clientPartnerData?._id}`,
          formData,
          { headers: { Authorization: `Bearer ${getCookie('eyevaryToken')}` } }
        )
        setAlert({
          show: true,
          variant: 'success',
          message: `${inputData.type} successfully updated.`,
          icon: 'mdi mdi-check',
          duration: 5,
        })
      } else {
        const { data } = await fetchData.post('/clientPartner', formData, {
          headers: { Authorization: `Bearer ${getCookie('eyevaryToken')}` },
        })
        setAlert({
          show: true,
          variant: 'success',
          message: `${inputData.type} successfully added.`,
          icon: 'mdi mdi-check',
          duration: 5,
        })
      }
      getClientPartners()
      hideModal()
    } catch (error) {
      console.log({ ...error }, error)
      setAlert({
        show: true,
        variant: 'danger',
        message:
          error?.response?.data?.message ||
          'Something went wrong. Please try again.',
        icon: 'mdi mdi-close',
        duration: 5,
      })
    }
    setSubmitting(false)
  }

  // ! hide modal
  const hideModal = () => {
    setModal(false)
    setEdit(false)
    setLogo()
    setInputData({
      name: '',
      type: '',
      joinedOn: '',
      status: false,
    })
    setClientPartnerData()
  }

  return (
    <Modal show={isModal} size='md' centered onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          {isEdit ? 'Update' : 'Create'} Client or Partner
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row>
            <Form.Group as={Col} xs={12} className='mb-3' controlId='name'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter brand name'
                name='name'
                value={inputData.name}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group as={Col} xs={12} className='mb-3' controlId='type'>
              <Form.Label>Type</Form.Label>
              <Form.Select
                name='type'
                value={inputData.type}
                onChange={handleChange}
                required>
                <option value='' disabled>
                  --Select--
                </option>
                <option value='Client'>Client</option>
                <option value='Partner'>Partner</option>
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} xs={12} className='mb-3' controlId='joinedOn'>
              <Form.Label>Joined On</Form.Label>
              <Form.Control
                type='date'
                name='joinedOn'
                value={inputData.joinedOn}
                onChange={handleChange}
              />
            </Form.Group>
            <DragDrop
              accept='image/*'
              name='logo'
              files={logo}
              setFiles={setLogo}
              onChange={handleChange}
            />
          </Row>
          <Form.Switch
            label={inputData.status ? 'Visible' : 'Hidden'}
            id='status'
            name='status'
            checked={inputData.status}
            onChange={handleChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button type='submit' disabled={isSubmitting}>
            {isEdit ? 'Update' : 'Submit'}
            {isSubmitting && (
              <Spinner animation='border' size='sm' className='ms-2' />
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default AddClientPartner
