import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row, Spinner, Table } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { AppContext } from '../context/AppContext'
import fetchData from '../utils/fetchData'
import getCookie from '../utils/getCookie'
import { staticUrl } from '../utils/url'

const About = () => {
  // ! context
  const { setAlert } = useContext(AppContext)

  // ! hooks
  const [isFetching, setFetching] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)
  const [inputData, setInputData] = useState({
    title: '',
    subtitle: '',
    description: '',
  })
  const [metaData, setMetaData] = useState({
    title: '',
    description: '',
    keywords: '',
    author: '',
    properties: [{ property: '', content: '' }],
  })
  const navigate = useNavigate()

  // ! get page information
  const getPageInformation = async () => {
    setFetching(true)
    try {
      const { data } = await fetchData.get('/page?name=about', {
        headers: {
          Authorization: `Bearer ${getCookie('eyevaryToken')}`,
        },
      })
      data?.page?.metaData && setMetaData(data?.page?.metaData)
      setInputData({
        title: data?.page?.pageData?.title || '',
        subtitle: data?.page?.pageData?.subtitle || '',
        description: data?.page?.pageData?.description || '',
      })
    } catch (error) {
      console.log({ ...error }, error)
      setAlert({
        show: true,
        variant: 'danger',
        message:
          error?.response?.data?.message ||
          'Something went wrong while fetching data. Please try again.',
        icon: 'mdi mdi-close',
        duration: 5,
      })
    }
    setFetching(false)
  }
  useEffect(() => {
    getPageInformation()
  }, [])

  // ! get input data
  const handleChange = ({ target }, from = 'normal', index) => {
    const { name, value, type, checked } = target
    if (from === 'normal') {
      setInputData({
        ...inputData,
        [name]: type === 'checkbox' ? checked : value,
      })
    } else if (from === 'meta') {
      if (index >= 0) {
        metaData.properties[index][name] = value
        setMetaData({ ...metaData })
      } else setMetaData({ ...metaData, [name]: value })
    }
  }

  // ! ckeditor image upload
  const uploadAdapter = (loader) => {
    return {
      upload: () =>
        new Promise((resolve, reject) => {
          const body = new FormData()
          loader.file
            .then(async (file) => {
              body.append('ckImage', file)
              try {
                const { data } = await fetchData.post('/page/ckimage', body, {
                  headers: {
                    Authorization: `Bearer ${getCookie('eyevaryToken')}`,
                  },
                })
                resolve({ default: staticUrl + data?.imageURI })
              } catch (error) {
                reject(error)
              }
            })
            .catch((error) => reject(error))
        }),
    }
  }
  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) =>
      uploadAdapter(loader)
  }

  // ! send data into the server
  const handleSubmit = async (e) => {
    e.preventDefault()
    setSubmitting(true)
    try {
      const { data } = await fetchData.patch(
        '/page?name=about',
        { pageData: inputData, metaData },
        { headers: { Authorization: `Bearer ${getCookie('eyevaryToken')}` } }
      )
      data?.page?.metaData && setMetaData(data?.page?.metaData)
      setInputData({
        title: data?.page?.pageData?.title || '',
        subtitle: data?.page?.pageData?.subtitle || '',
        description: data?.page?.pageData?.description || '',
      })
      setAlert({
        show: true,
        variant: 'success',
        message: 'About page information successfully updated',
        icon: 'mdi mdi-check',
        duration: 5,
      })
      // navigate('/')
    } catch (error) {
      console.log({ ...error }, error)
      setAlert({
        show: true,
        variant: 'danger',
        message:
          error?.response?.data?.message ||
          'Something went wrong. Please try again.',
        icon: 'mdi mdi-close',
        duration: 5,
      })
    }
    setSubmitting(false)
  }

  return (
    <section className='content-wrapper'>
      <Form onSubmit={handleSubmit}>
        <Card className='mb-4'>
          <Card.Header>About Information</Card.Header>
          <Card.Body>
            <Form.Group controlId='title' className='mb-3'>
              <Form.Label>Title</Form.Label>
              <Form.Control
                type='text'
                name='title'
                value={inputData.title}
                onChange={(e) => handleChange(e, 'normal')}
                required
              />
            </Form.Group>
            <Form.Group controlId='subtitle' className='mb-3'>
              <Form.Label>Subtitle</Form.Label>
              <Form.Control
                as='textarea'
                rows='4'
                name='subtitle'
                value={inputData.subtitle}
                onChange={(e) => handleChange(e, 'normal')}
                required
              />
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>Description</Form.Label>
              <CKEditor
                editor={Editor}
                config={{
                  extraPlugins: [uploadPlugin],
                }}
                data={inputData.description}
                onChange={(event, editor) => {
                  const description = editor.getData()
                  !isFetching && setInputData({ ...inputData, description })
                }}
              />
            </Form.Group>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>Meta Information</Card.Header>
          <Card.Body>
            <Form.Group controlId='title' className='mb-3'>
              <Form.Label>Meta Title</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter meta title'
                name='title'
                value={metaData.title}
                onChange={(e) => handleChange(e, 'meta')}
              />
            </Form.Group>
            <Form.Group controlId='description' className='mb-3'>
              <Form.Label>Meta Description</Form.Label>
              <Form.Control
                as='textarea'
                rows='5'
                placeholder='Enter meta description'
                name='description'
                value={metaData.description}
                onChange={(e) => handleChange(e, 'meta')}
              />
            </Form.Group>
            <Row>
              <Form.Group as={Col} md={6} controlId='keywords' className='mb-3'>
                <Form.Label>Meta Keyword</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter meta keyword'
                  name='keywords'
                  value={metaData.keywords}
                  onChange={(e) => handleChange(e, 'meta')}
                />
              </Form.Group>
              <Form.Group as={Col} md={6} controlId='author' className='mb-3'>
                <Form.Label>Meta Author</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter meta author'
                  name='author'
                  value={metaData.author}
                  onChange={(e) => handleChange(e, 'meta')}
                />
              </Form.Group>
            </Row>
            <p className='fw-bold mb-2'>Meta Properties</p>
            <Table responsive bordered>
              <thead>
                <tr>
                  <th style={{ width: '3rem' }}></th>
                  <th style={{ minWidth: '15rem' }}>Property</th>
                  <th style={{ minWidth: '25rem' }}>Content</th>
                </tr>
              </thead>
              <tbody>
                {metaData.properties.map((property, i) => (
                  <tr key={i}>
                    <td
                      className='align-middle text-center pnt'
                      onClick={() => {
                        metaData.properties.filter((property, j) => j !== i)
                        setMetaData({
                          ...metaData,
                          properties: metaData.properties.filter(
                            (property, j) => j !== i
                          ),
                        })
                      }}>
                      <span className='text-primary'>
                        <i className='mdi mdi-delete'></i>
                      </span>
                    </td>
                    <td className='align-middle'>
                      <Form.Control
                        name='property'
                        value={property.property}
                        onChange={(e) => handleChange(e, 'meta', i)}
                        required
                      />
                    </td>
                    <td className='align-middle'>
                      <Form.Control
                        name='content'
                        value={property.content}
                        onChange={(e) => handleChange(e, 'meta', i)}
                        required
                      />
                    </td>
                  </tr>
                ))}
                <tr>
                  <td
                    colSpan='3'
                    className='align-middle text-center pnt'
                    onClick={() => {
                      setMetaData({
                        ...metaData,
                        properties: [
                          ...metaData.properties,
                          { property: '', content: '' },
                        ],
                      })
                    }}>
                    <span className='text-success'>
                      <i className='mdi mdi-plus'></i>
                    </span>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
          <Card.Footer>
            <div className='text-end'>
              <Button size='sm' type='submit' disabled={isSubmitting}>
                Submit
                {isSubmitting && (
                  <Spinner animation='border' size='sm' className='ms-2' />
                )}
              </Button>
            </div>
          </Card.Footer>
        </Card>
      </Form>
    </section>
  )
}

export default About
